import { useMediaQuery, useTheme } from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";

// Create the context
const DrawerContext = createContext<any>(null);

// Create a provider for drawer sidebar component
export const DrawerProvider = ({ children }: { children: React.ReactNode }) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const theme = useTheme();
  const IsMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = () => {
    setDrawerOpen((prevOpen) => !prevOpen);
  };

  // Effect to synchronize drawer state with mobile view
  useEffect(() => {
    setDrawerOpen(!IsMobile); // Close drawer if the screen becomes mobile
  }, [IsMobile]);

  return (
    <DrawerContext.Provider value={{ drawerOpen, toggleDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};

// Custom hook to use the DrawerContext
export const useDrawer = () => useContext(DrawerContext);
