import CryptoJS  from "crypto-js";

const secretKey = process.env.REACT_APP_SECRET_KEY || ''; 
const iv = process.env.REACT_APP_IV || ''; 
// Utility function to decrypt data
export function decryptData(encryptedData : any) {
    if (!secretKey || !iv) {
        throw new Error("Encryption configuration is missing.");
      }
      let base64String = encryptedData.replace(/-/g, '+').replace(/_/g, '/');

      // Add padding if missing
      while (base64String.length % 4 !== 0) {
          base64String += '=';
      }
         const decrypted = CryptoJS.AES.decrypt(base64String, CryptoJS.enc.Utf8.parse(secretKey), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  
  return decrypted.toString(CryptoJS.enc.Utf8);
}
