import {
  Modal,
  Box,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
} from "@mui/material";
import { useModal } from "../../utils";
import ConfirmationDialogStyle from "../ConfirmationDialog/ConfirmationDialogStyle";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";

const CustomModal = () => {
  const { isOpen, message, closeModal }: any = useModal();

  return (
    <>
      <ConfirmationDialogStyle open={isOpen}>
        <DialogContent className="session_dialog_container">
          <Grid container>
            <Grid
              item
              xs={12}
              className="inactive_dialog_content session_expire_dialog"
            >
              <h3>Session Expire</h3>
              <h5>{message}</h5>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="session_dialogbtn_container">
          <Button fullWidth variant="contained" onClick={closeModal}>
            Sign in
          </Button>
        </DialogActions>
      </ConfirmationDialogStyle>

      {/* <Modal
            open={isOpen}
            onClose={closeModal}
            aria-labelledby="custom-modal-title"
            aria-describedby="custom-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="custom-modal-title" sx={{ display: "flex", justifyContent: "center" }} variant="h6" component="h2">
                    <img src="~/logo.svg" alt="" />
                </Typography>
                <Typography id="custom-modal-description" sx={{ mt: 3, mb: 3, lineHeight: "1.2", textAlign: "center" }}>
                    
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "#414ed5",
                        width: "100%",
                        color: "#fff",
                        fontWeight: "500",
                        '&:hover': {
                            backgroundColor: "#333ab4",
                            opacity: 1
                        }
                    }}
                    onClick={closeModal}
                >
                    Sign In
                </Button>
            </Box>
        </Modal> */}
    </>
  );
};

export default CustomModal;
