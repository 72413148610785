import React, { useCallback, useState, useEffect, Suspense } from "react";
import styles from "./header.module.scss";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Icon from "@mdi/react";
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiChevronDown,
  mdiChevronLeftCircle,
  mdiClose,
  mdiMenu,
  mdiPlus,
} from "@mdi/js";
import { useDrawer } from "../../utils/DrawerContext";
import { useLocation, useNavigate } from "react-router-dom";

import { AddClientDialog } from "../../containers";
import { clientService, userService } from "../../api/services";
import { IClient, IRole, IUser } from "../../interfaces";
import { UserRoles } from "../../utils/Enum/UserRole";
import { useClient } from "../../utils/ClientContext";
import NoAccessDialog from "../../containers/Mat2rix/NoAccessDialog/NoAccessDialog";
import { useAuth } from "../../utils/AuthContext";

const Header: React.FC = () => {
  const theme = useTheme();
  const { isAuthenticated } = useAuth();
  const { setClient, userUpdated, setUserUpdated } = useClient();
  const IsMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { drawerOpen, toggleDrawer } = useDrawer();
  const [avatarMenu, setAvatarMenu] = React.useState<null | HTMLElement>(null);
  const [addClientDialogOpen, setAddClientDialogOpen] = React.useState(false);
  const openAvatarMenu = Boolean(avatarMenu);
  const [clients, setClients] = useState<IClient[]>([]);
  const [user, setUser] = useState<IUser>();
  const storedClientId = localStorage.getItem("clientId");
  const [clientId, setClientId] = useState<number>(
    storedClientId ? Number(storedClientId) : 0
  );
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showCompanyBtn, setShowCompanyBtn] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error" | "warning">(
    "success"
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const userId = localStorage.getItem("userId");
  const { logout } = useAuth();
  const [userName, setUserName] = useState("");

  const onAvatarMenuClicked = (event: React.MouseEvent<HTMLElement>) => {
    setAvatarMenu(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarMenu(null);
  };
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();
  const isMat2rixPage =
    location.pathname === "/" ||
    location.pathname === "/users" ||
    location.pathname === "/my-profile"; // Check if current path is login
  const isLoginPage = location.pathname === "/login";
  const handleRefresh = () => {
    fetchClients();
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    if (!isLoginPage && isAuthenticated) {
      fetchClients();
      fetchUser();
    }
  }, [userId]);
  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };
  useEffect(() => {
    if (userUpdated) {
      fetchUser();
      setUserUpdated(false);
    }
  }, [userUpdated, setUserUpdated]);
  const fetchClients = async () => {
    try {
      setClientsLoading(true);
      const response = await clientService.fetchClients(userId);
      if (response.clients.length === 0) {
        setSnackbarMessage(
          "There is no company created. Please create a company to proceed with the inventory"
        );
        setSeverity("warning");
        setOpenSnackbar(true);
      }
      setClients(response.clients);

      const existingClient = response.clients.find(
        (client: IClient) => client.id === clientId
      );
      if (existingClient) {
        setClientId(existingClient.id);
        setClient(existingClient);
        setShowCompanyBtn(false);
      } else if (response.clients.length > 0) {
        setClient(response.clients[0]);
        localStorage.setItem("clientId", response.clients[0].id.toString());
        localStorage.setItem("clientName", response.clients[0].name);
        setClientId(response.clients[0].id);
        setShowCompanyBtn(false);
      } else {
        setShowCompanyBtn(true);
      }
    } catch (error) {
      setSnackbarMessage("Failed to get Clients");
      setSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setClientsLoading(false);
    }
  };

  //Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const [noAccessDialog, setNoAccessDialog] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const [clientsLoading, setClientsLoading] = useState(true);

  const fetchUser = async () => {
    try {
      setUserLoading(true);
      const response = await userService.fetchUserById(userId);
      setUser(response);

      if (response.isSuccess) {
        localStorage.setItem("UserDetails", JSON.stringify(response));

        // Check if roles array is empty, and if so, set no access dialog
        if (!response.roles || response.roles.length === 0) {
          setNoAccessDialog(true);
        } else {
          // Check if user is SuperAdmin or Admin
          const isSuperAdmin = response.roles.some(
            (role: IRole) => role.roleId === UserRoles.SuperAdmin
          );
          setIsSuperAdmin(isSuperAdmin);

          setIsAdmin(
            response.roles.some(
              (role: IRole) => role.roleId === UserRoles.Admin
            )
          );
        }
      }
    } catch (error) {
      console.error("Error fetching user details", error);
      setSnackbarMessage("Failed to get User details");
      setSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setUserLoading(false);
    }
  };

  const handleClient = (event: SelectChangeEvent) => {
    const selectedId = Number(event.target.value);
    const selectedClient = clients.find((client) => client.id === selectedId);

    if (selectedClient) {
      setClientId(selectedId);
      setClient(selectedClient);
      localStorage.setItem("clientId", JSON.stringify(selectedId));
      localStorage.setItem("clientName", selectedClient.name);
    }
  };
  const onClickAddClientDialogOpen = () => {
    setAddClientDialogOpen(true);
  };
  const onClickAddClientDialogClose = useCallback(() => {
    setAddClientDialogOpen(false);
  }, []);

  const handleNavigateProfile = () => {
    navigate("/my-profile");
    handleAvatarClose();
  };

  return (
    <header>
      <div className={styles.logoContainer}>
        <Link className={styles.textLogo} href="/">
          <h3> {!isMat2rixPage ? "ICM" : "Met2Rix"} </h3>
        </Link>
      </div>
      {!IsMobile ? (
        <div className={styles.rightOptions}>
          <div className={styles.companyDropdown}>
            {!showCompanyBtn && (
              <FormControl variant="filled" className={styles.fieldWrapper}>
                <Select
                  labelId="company_select"
                  id="company"
                  displayEmpty
                  value={clientId ? clientId.toString() : ""} // Convert number to string, fallback to empty string if not selected
                  onChange={handleClient}
                  sx={{
                    "& .MuiInputBase-input": {
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      minWidth: "150px",
                      "@media screen and (max-width:768px)": {
                        minWidth: "100px", // Smaller padding for mobile
                      },
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflowY: "auto",
                      },
                    },
                  }}
                >
                  {Array.isArray(clients) && clients.length > 0 ? (
                    clients.map((client) => (
                      <MenuItem key={client.id} value={client.id.toString()}>
                        {/* Convert ID to string */}
                        {client.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="0">No Clients Available</MenuItem>
                  )}
                  {isSuperAdmin && (
                    <MenuItem
                      onClick={onClickAddClientDialogOpen}
                      style={{ fontWeight: "bold" }}
                    >
                      <Icon path={mdiPlus} size={1} /> Add Company
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
            {showCompanyBtn && isSuperAdmin && (
              <Button
                onClick={onClickAddClientDialogOpen}
                sx={{
                  background: "#F0F1F8",
                  border: "none",
                  color: "#000",
                  "& .MuiButton-root": {
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    minWidth: "150px",
                    "@media screen and (max-width:768px)": {
                      minWidth: "100px", // Smaller padding for mobile
                    },
                  },
                }}
              >
                <Icon path={mdiPlus} size={1} /> Add Company
              </Button>
            )}
          </div>
          <Button
            className={styles.userProfile}
            variant="outlined"
            onClick={onAvatarMenuClicked}
            aria-controls={openAvatarMenu ? "avatar-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openAvatarMenu ? "true" : undefined}
          >
            <Avatar
              className={styles.avatarCircle}
              // sx={{ bgcolor: "#3F4BDB" }}
              // alt={userName}
              src="/broken-image.jpg"
              // onClick={onAvatarMenuClicked}
            />
            <div className={styles.welcomeText}>
              {!IsMobile && (
                <>{user ? <>{user.firstName + " " + user.lastName}</> : " "}</>
              )}
              <Icon path={mdiChevronDown} size={1} />
            </div>
          </Button>
          <Menu
            id="avatar-menu"
            aria-labelledby="avatar-button"
            anchorEl={avatarMenu}
            open={openAvatarMenu}
            onClose={handleAvatarClose}
            className={styles.headerDropMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  width: "250px",
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              },
            }}
          >
            {IsMobile && (
              <MenuItem>
                {user ? <>{user.firstName + " " + user.lastName}</> : "Guest"}
              </MenuItem>
            )}
            <MenuItem onClick={handleNavigateProfile}>My Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      ) : (
        <div className={styles.rightOptions}>
          <IconButton
            className={styles.drawerOpenButton}
            onClick={toggleDrawer}
          >
            <Icon path={drawerOpen ? "" : mdiMenu} size={1} />
          </IconButton>
        </div>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        <AddClientDialog
          open={addClientDialogOpen}
          onClose={onClickAddClientDialogClose}
          onSuccess={handleRefresh}
        />
      </Suspense>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {!userLoading && !clientsLoading && noAccessDialog && (
        <NoAccessDialog open={noAccessDialog} />
      )}
    </header>
  );
};

export default Header;
