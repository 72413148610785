import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import styles from "./mat2rix.module.scss";
import { useNavigate } from "react-router-dom";
import { mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import NoAccessDialog from "./NoAccessDialog/NoAccessDialog";
import { useClient } from "../../utils/ClientContext";
import { IRole } from "../../interfaces/IRole";
import { UserRoles } from "../../utils/Enum/UserRole";

const Mat2rix: React.FC = () => {
    const [NoAccessForUser, setNoAccessForUser] = useState(false);

    const navigate = useNavigate();

    const handleAdminNavigation = () => {
      let isAdmin: any;
        const storedUserDetails = localStorage.getItem("UserDetails");
        if (storedUserDetails) {
            const userDetails = JSON.parse(storedUserDetails);
            if (userDetails && Array.isArray(userDetails.roles)) {
                isAdmin = userDetails.roles.some(
                    (role: IRole) =>
                        role.roleId === UserRoles.Admin ||
                        role.roleId === UserRoles.SuperAdmin
                );
            }
        }
        if (isAdmin) {
            navigate("/ICM", { replace: true });
        }
        else {
            navigate("/user-dashboard", { replace: true });
        }
    };

    const handleMasterNavigation = () => {
        navigate("/settings/masters", { replace: true });
    };
    const { client } = useClient();
    const clientName = client.name
    const [name, setName] = useState<string>();
    useEffect(() => {
        if (clientName) {
            setName(clientName);
        }
    }, [clientName]);
    return (
        <div className="content_container">
            <Grid container item spacing={2}>
                <Grid item xs={12}>
                    <h3 className={styles.page_title}>{name}</h3>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12}>
                    <Box className={styles.outerBox} onClick={handleAdminNavigation}>
                        <div className={styles.boxContent}>
                            <h3> Intelligent Count Module </h3>
                            <p>
                                Automate the counting process, reduce manual labor and increase
                                accuracy, to make data-driven decisions.
              </p>
                        </div>
                        <Icon
                            path={mdiChevronRight}
                            size={1}
                            color="#40475B"
                            className={styles.arrowIcon}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Suspense fallback={<div>Loading...</div>}>
                {NoAccessForUser && <NoAccessDialog open={NoAccessForUser} />}
            </Suspense>
        </div>
    );
};
export default Mat2rix;
