import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  DialogTitle,
  IconButton,
  Snackbar,
  Grid,
  Alert,
  InputAdornment,
} from "@mui/material";
import {
  IAddInventoryDialogProps,
  IAddInventoryProp,
} from "../../../interfaces";
import Icon from "@mdi/react";
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiClose,
  mdiPercentOutline,
} from "@mdi/js";
import { DialogStyle } from "../../../components";
import { inventoryService } from "../../../api/services";
import { useClient } from "../../../utils/ClientContext";

const AddInventoryDialog: React.FC<IAddInventoryDialogProps> = ({
  open,
  onClose,
  onSubmit,
  onSuccess,
}) => {
  const { client } = useClient();
  const clientId = client.id;
  const userId = Number(localStorage.getItem("userId"));
  const [inventoryData, setInventoryData] = useState<IAddInventoryProp>({
    name: "",
    clientId: clientId,
    createdBy: userId,
    isActive: false,
    auditThreshold: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [errors, setErrors] = useState({
    name: "",
    auditThreshold: "",
  });

  // Handle input field change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const hasInvalidCharacters = /[^a-zA-Z0-9\s]/.test(value);

    setInventoryData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (name === "auditThreshold") {
      const numberValue = parseFloat(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]:
          value === ""
            ? "Audit threshold is required."
            : isNaN(numberValue)
            ? "Audit threshold must be a number."
            : !Number.isInteger(numberValue)
            ? "Audit threshold must be a whole number."
            : numberValue < 1 || numberValue > 100
            ? "Audit threshold must be between 1 and 100."
            : "",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]:
          value === ""
            ? "Inventory name is required"
            : hasInvalidCharacters
            ? "Invalid Inventory name."
            : "",
      }));
    }
  };

  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Validate form
  const validateForm = () => {
    const newErrors: any = { name: "", auditThreshold: "" };
    const auditThresholdValue = Number(inventoryData.auditThreshold);

    if (!inventoryData.name) {
      newErrors.name = "Inventory Name is required.";
    } else if (inventoryData.name.length > 20) {
      newErrors.name = "Inventory name must be 20 characters or less.";
    } else if (/[^a-zA-Z0-9\s]/.test(inventoryData.name)) {
      newErrors.name = "Invalid Inventory name.";
    }

    if (!inventoryData.auditThreshold) {
      newErrors.auditThreshold = "Audit threshold is required.";
    } else if (isNaN(auditThresholdValue)) {
      newErrors.auditThreshold = "Audit threshold must be a number.";
    } else if (!Number.isInteger(auditThresholdValue)) {
      newErrors.auditThreshold = "Audit threshold must be a number.";
    } else if (auditThresholdValue < 1 || auditThresholdValue > 100) {
      newErrors.auditThreshold = "Audit threshold must be between 1 and 100.";
    }

    setErrors(newErrors);
    return !newErrors.name && !newErrors.auditThreshold;
  };

  // Reset form
  const resetForm = () => {
    setInventoryData({
      name: "",
      clientId,
      createdBy: userId,
      isActive: false,
      auditThreshold: "",
    });
    setErrors({
      name: "",
      auditThreshold: "",
    });
  };

  useEffect(() => {
    setIsFormValid(
      inventoryData.name !== "" && inventoryData.auditThreshold !== ""
    );
  }, [inventoryData, clientId]);

  // Close inventory dialog
  const onClickAddInventoryDialogClose = () => {
    if (onClose) {
      onClose();
      resetForm();
    }
  };

  // Save inventory
  const onClickAddInventoryDialogSubmit = async () => {
    try {
      if (validateForm()) {
        const dataToSubmit = {
          ...inventoryData,
          auditThreshold: Number(inventoryData.auditThreshold),
        };
        const response = await inventoryService.createInventoryAsync(
          dataToSubmit
        );
        if (response.isSuccess) {
          onSuccess(response.isSuccess, "Inventory created successfully.");
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: response.errors.join(", "),
          }));
          setSeverity("error");
          setSnackbarMessage(response.errors.join(", "));
          setOpenSnackbar(true);
          setTimeout(() => {
            handleCloseSnackbar();
          }, 5000);
        }
      }
    } catch (error: any) {
      const errorMessages = error.response?.data?.errors || [
        "An unexpected error occurred.",
      ];
      if (error.response?.data?.errors) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: errorMessages.join(", "),
        }));
      }
      setSeverity("error");
      setSnackbarMessage(errorMessages.join(", "));
      setOpenSnackbar(true);
      setTimeout(() => {
        handleCloseSnackbar();
      }, 5000);
    }
  };

  return (
    <DialogStyle open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Add Inventory Session Name
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClickAddInventoryDialogClose}
        sx={() => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: "#FFF",
        })}
      >
        <Icon path={mdiClose} size={1} />
      </IconButton>
      <DialogContent>
        <Grid container>
          <Grid item sm={12} className="AddInventory_img">
            <img
              src={process.env.PUBLIC_URL + "/assets/AddInventory.svg"}
              alt=" "
            />
          </Grid>
        </Grid>
        <FormControl className="fieldWrapper" fullWidth>
          <TextField
            name="name"
            id="name"
            value={inventoryData.name}
            label="Enter the Name"
            required
            placeholder="Inventory Name"
            onChange={handleChange}
            onKeyDown={(e) => {
              if (!/^[a-zA-Z0-9\b]*$/.test(e.key)) {
                e.preventDefault();
              }
            }}
            error={Boolean(errors.name)}
            helperText={errors.name}
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            id="auditThreshold"
            name="auditThreshold"
            value={inventoryData.auditThreshold}
            label="Audit Threshold"
            required
            placeholder="Audit Threshold"
            onChange={handleChange}
            error={Boolean(errors.auditThreshold)}
            helperText={errors.auditThreshold || ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon path={mdiPercentOutline} size={0.8} />
                </InputAdornment>
              ),
              inputProps: { min: 1, max: 100, step: 1, type: "number" },
            }}
          />
          <p style={{ fontSize: "12px" }}>
            Mention your inventory name - Session period preferably for an ideal
            Inventory Name{" "}
          </p>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel"
          fullWidth
          variant="outlined"
          onClick={onClickAddInventoryDialogClose}
        >
          Cancel
        </Button>
        <Button
          id="Proceed"
          fullWidth
          variant="contained"
          disabled={!isFormValid}
          onClick={onClickAddInventoryDialogSubmit}
        >
          Proceed
        </Button>
      </DialogActions>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </DialogStyle>
  );
};

export default AddInventoryDialog;
