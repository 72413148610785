import ICMAxios from '../ICMAxios';
import { ICreateLocation } from '../../interfaces/ICreateLocation';
import { IUpdateLocation } from '../../interfaces/IUpdateLocation';

const locationService = {
  createLocationAsync: (location: ICreateLocation): Promise<any> => 
    ICMAxios.post<any>('/Location/CreateLocationAsync', location),

  getAllLocations: (clientId: number, request: any): Promise<any> => 
    ICMAxios.post<any>(`/Location/GetAllLocationsAsync/${clientId}`, request),

  getLocationById: (locationId: number): Promise<any> => 
    ICMAxios.get<any>(`/Location/GetLocationByIdAsync/${locationId}`),

  updateLocation: (request: IUpdateLocation): Promise<any> => 
    ICMAxios.put<any>('/Location/UpdateLocationAsync', request),

  updateStatusAsync: (request: any): Promise<any> => 
    ICMAxios.put<any>('/Location/UpdateStatusAsync', request),

  getAreas: (clientId: number): Promise<any> => 
    ICMAxios.get<any>(`/Location/GetAreasAsync/${clientId}`)
};

export default locationService;