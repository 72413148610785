import ICMAxios from "../ICMAxios";

const fetchUserById = async (userId: any) => {
    const response = await ICMAxios.get(`User/GetUserDetailsByIdAsync/${userId}`);
    return response.data;
}
 
const changePassword = async (data: any) => {
    const response = await ICMAxios.post(`User/ChangePasswordAsync`, data);
    return response.data;
}
const updateProfile = async (data: any) => {
    const response = await ICMAxios.post(`User/UpdateProfileAsync`, data);
    return response.data;
}

const generateQrCode = async (clientId: number) => {
    const response = await ICMAxios.get(`User/generateClientQRCodeAsync/${clientId}`);
    return response.data;
}
const signUp = async (data: any) => {
    const response = await ICMAxios.post(`User/SignUp`, data);
    return response.data;
}
// Get User List By Client 
const getUserList = async (payload: any) => {
    const response = await ICMAxios.post(`User/getUserList`, payload);
    return response.data;
}
// Update user status 
const updateUserStatus = async (payload: any) => {
    const response = await ICMAxios.put(`User/UpdateUserClientStatus`, payload);
    return response.data;
}
// Update user role
const updateUserRole = async (payload: any) => {
    const response = await ICMAxios.put(`User/UpdateUserRole`, payload);
    return response.data;
}
// Send Forgot Password Email
const sendForgotPasswordEmail = async (payload: any) => {
    const response = await ICMAxios.post(`User/SendForgotPasswordEmail`, payload);
    return response.data;
}
const forgotPassword = async (data: any) => {
    const response = await ICMAxios.put(`User/ForgotPasswordAsync`, data);
    return response.data;
}
const userService = {forgotPassword, sendForgotPasswordEmail,fetchUserById,changePassword, updateProfile, generateQrCode, signUp, getUserList,updateUserStatus, updateUserRole}
export default userService;