import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  DialogTitle,
  IconButton,
  InputLabel,
  Autocomplete,
  InputAdornment,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import { IChangePasswordDialogProps } from "../../../interfaces";
import Icon from "@mdi/react";
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiChevronDown,
  mdiClose,
  mdiEyeOffOutline,
  mdiEyeOutline,
} from "@mdi/js";
import { DialogStyle } from "../../../components";
import { userService } from "../../../api/services";

const ChangepasswordDialog: React.FC<IChangePasswordDialogProps> = ({
  open,
  onClose,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const userId = localStorage.getItem("userId");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // Create a state variable for the input value
  const [formValues, setFormValues] = useState({
    currentPassword: "",
    newPassword: "",
  });

  const [errors, setErrors] = useState({
    currentPassword: false,
    newPassword: false,
  });
  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  // Create an onChange handler
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenSnackbar(false);
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value, // Use the name attribute to update the correct field
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === "", // Set error if field is empty
    }));
  };

  const resetForm = () => {
    setFormValues({
      currentPassword: "",
      newPassword: "",
    });
    setErrors({
      currentPassword: false,
      newPassword: false,
    });
  };

  const validateForm = () => {
    const newErrors = {
      currentPassword: formValues.currentPassword === "",
      newPassword:
        formValues.newPassword === "" ||
        formValues.newPassword === formValues.currentPassword,
    };
    setErrors(newErrors);

    return !newErrors.currentPassword && !newErrors.newPassword;
  };

  const [isFormValid, setIsFormValid] = useState(false);

  // Validate form
  useEffect(() => {
    const isValid =
      formValues.currentPassword !== "" && formValues.newPassword !== "";
    setIsFormValid(isValid);
  }, [formValues]);

  const onClickChangepasswordDialogClose = () => {
    if (onClose) {
      onClose();
      resetForm();
    }
  };

  const onClickChangepasswordDialogSubmit = async () => {
    if (validateForm()) {
      try {
        const payload = {
          userId: userId,
          currentPassword: formValues.currentPassword,
          newPassword: formValues.newPassword,
        };

        const response = await userService.changePassword(payload);

        if (response.isSuccess) {
          setSnackbarMessage("Password saved successfully.");
          setSeverity("success");
          setOpenSnackbar(true);
          setTimeout(() => {
            setOpenSnackbar(false);
            onClickChangepasswordDialogClose();
          }, 1000);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
          }));
        }
      } catch (error: any) {
        const errorMessages = error.response?.data?.errors || [
          "Error occurred while changing password.",
        ];
        setSnackbarMessage(errorMessages.join(", "));
        setSeverity("error");
        setOpenSnackbar(true);
        setErrors({ ...errors });
      }
    }
  };

  return (
    <DialogStyle open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Change Password
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClickChangepasswordDialogClose}
        sx={() => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: "#FFF",
        })}
      >
        <Icon path={mdiClose} size={1} />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <FormControl className="fieldWrapper" fullWidth>
              <TextField
                id="password"
                name="currentPassword"
                type={showPassword ? "text" : "password"}
                value={formValues.currentPassword}
                label="Current Password"
                required
                placeholder="Enter Your Password"
                onChange={handleChange}
                InputProps={{
                  endAdornment: formValues.currentPassword && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <Icon path={mdiEyeOffOutline} size={0.7} />
                        ) : (
                          <Icon path={mdiEyeOutline} size={0.7} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.currentPassword}
                helperText={
                  errors.currentPassword ? "Password is required" : ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl className="fieldWrapper" fullWidth>
              <TextField
                id="confirmPassword"
                name="newPassword"
                type={showConfirmPassword ? "text" : "password"}
                value={formValues.newPassword}
                label="New Password"
                required
                placeholder="Enter Your Password"
                onChange={handleChange}
                InputProps={{
                  endAdornment: formValues.newPassword && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Icon path={mdiEyeOffOutline} size={0.7} />
                        ) : (
                          <Icon path={mdiEyeOutline} size={0.7} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.newPassword}
                helperText={
                  errors.newPassword
                    ? formValues.newPassword === formValues.currentPassword
                      ? "The current and new passwords must not match."
                      : "New password is required"
                    : ""
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="outlined"
          onClick={onClickChangepasswordDialogClose}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          variant="contained"
          disabled={!isFormValid} // Disable button if form is invalid
          onClick={onClickChangepasswordDialogSubmit}
        >
          Save
        </Button>
      </DialogActions>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </DialogStyle>
  );
};
export default ChangepasswordDialog;
