import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useModal, useAuth } from "../utils";
import { IRole } from "../interfaces/IRole";
import { UserRoles } from "../utils/Enum/UserRole";
import NoAccessDialog from "../containers/Mat2rix/NoAccessDialog/NoAccessDialog";

interface ProtectedRouteProps {
    children: JSX.Element;
    allowedRoles?: UserRoles[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRoles = [] }: any) => {
    const { openModal }: any = useModal();
    const location = useLocation();
    const token = localStorage.getItem("token");
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const lastActivity = parseInt(localStorage.getItem("lastActivity") || "0");
    const INACTIVITY_LIMIT = 20 * 60 * 1000;
    const { isAuthenticated } = useAuth();

    if (!token) {
        return location.pathname === "/login" ? children : <Navigate to="/login" />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    if (isTokenExpired(token)) {
        return openModal("Your session is expired. Please Sign In again.");
    }

    if (!rememberMe && Date.now() - lastActivity > INACTIVITY_LIMIT) {
        localStorage.clear();
        return <Navigate to="/login" />;
    }

    if (location.pathname === "/login") {
        return <Navigate to="/" />;
    }

    // Retrieve user roles
    const userDetails = JSON.parse(localStorage.getItem("UserDetails") || "{}");
    const userRoleIds = userDetails.roles?.map((role: IRole) => role.roleId) || [];

    // Check if user has any of the allowed roles
    const hasAccess = allowedRoles.length === 0 || allowedRoles.some((role: any) => userRoleIds.includes(role));

    if (!hasAccess) {
        return <NoAccessDialog open={true} showHome={true} />; // Redirect to an unauthorized page or fallback route
    }
    return children;
};

const isTokenExpired = (token: string): boolean => {
    try {
        const jwtData = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        const tokenExpirationTime = jwtData?.exp ?? 0;

        return tokenExpirationTime < currentTime;
    } catch (error) {
        console.error("Token decoding failed or invalid token", error);
        return true;
    }
};


export default ProtectedRoute;
