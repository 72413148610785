import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Icon } from "@mdi/react";
import { mdiClose, mdiMagnify } from "@mdi/js";
import { ISearchInputProps } from "../../interfaces";

const SearchInput: React.FC<ISearchInputProps> = ({
  searchKey,
  onChange,
  placeholder = "Search by area, location and item...",
}) => {
  return (
    <TextField
      fullWidth
      placeholder={placeholder}
      variant="outlined"
      value={searchKey}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchKey ? (
              <IconButton
                aria-label="clear search"
                onClick={() => onChange("")} // Clear the search key
              >
                <Icon path={mdiClose} size={0.8} />
              </IconButton>
            ) : (
              <Icon path={mdiMagnify} size={1} />
            )}
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiInputBase-root": {
          background: "#FFF",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          color: "#000",
          borderRadius: "4px",
          borderColor: "var(--primary-color)",
        },
        "& .MuiInputBase-input": {
          background: "#FFF",
        },
      }}
    />
  );
};

export default SearchInput;
