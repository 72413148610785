import React, { useState, useEffect } from "react";
import styles from "./inventoryDetailsTagTile.module.scss";
import {
  Grid,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Icon from "@mdi/react";
import {
  mdiDotsVertical,
  mdiChevronRight,
  mdiCheck,
  mdiClose,
  mdiAlertCircleOutline,
} from "@mdi/js";
import { IInventoryDetailsTileProps } from "../../../../interfaces";
import { useNavigate } from "react-router-dom";
import { inventoryService } from "../../../../api/services";
import { ConfirmationDialog, InactiveDialog } from "../../../../components";
import { DialogMessage } from "../../../../utils/Enum/DialogMessage";
import { UserRoles } from "../../../../utils/Enum/UserRole";

const InventoryDetailsTagTile: React.FC<IInventoryDetailsTileProps> = ({
  tagId,
  itemName,
  itemId,
  description,
  primaryUOM,
  secondaryUOM,
  count,
  audit,
  finalValue,
  forceAudit,
  showForceAudit,
  assignTagPopup,
  isLocked,
  counterUserId,
  auditorUserId,
  tagNumber,
}) => {
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [showForceAuditCheckBox, setShowForceAuditCheckBox] =
    useState<boolean>(false);
  const [forceAudited, setForceAudited] = useState<boolean>(false);
  const [inactiveDialogOpen, setInactiveDialogOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [callForceAudit, setCallForceAudit] = useState(false);
  const userId = localStorage.getItem("userId");

  const handleOnConfirm = async () => {
    if (callForceAudit) {
      try {
        const payload = {
          tagId: tagId,
          modifiedBy: userId,
        };
        const response = await inventoryService.updateTagForceAudit(payload);
        if (response.isSuccess) {
          setForceAudited(!forceAudited);
          setSnackbarMessage("Force audit updated successfully!");
          setSeverity("success");
          setOpenSnackbar(true);
          setTimeout(() => {
            handleCloseSnackbar();
          }, 5000);
        }
      } catch (error) {
        setSnackbarMessage("Failed to update force audit.");
        setSeverity("error");
        setOpenSnackbar(true);
      } finally {
        setInactiveDialogOpen(false);
      }
    } else {
      try {
        const payload = {
          tagId: tagId,
          modifiedBy: userId,
        };
        const response = await inventoryService.excludeTagFromNextInventory(
          payload
        );
        if (response.isSuccess) {
          setSnackbarMessage(
            "Tag successfully excluded from being copied to the next inventory session. It will not appear when you copy this inventory to a new session."
          );
          setSeverity("success");
          setOpenSnackbar(true);
          setTimeout(() => {
            handleCloseSnackbar();
          }, 5000);
        }
      } catch (error) {
        setSnackbarMessage("Failed to exclude tag.");
        setSeverity("error");
        setOpenSnackbar(true);
      } finally {
        setInactiveDialogOpen(false);
      }
    }
  };

  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };

  //Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    setShowForceAuditCheckBox(showForceAudit);
    setForceAudited(forceAudit);
  }, [forceAudit, showForceAudit]);

  const navigate = useNavigate(); // Hook to navigate back
  const [assignTagMenuEl, setAssignTagMenuEl] = useState<null | HTMLElement>(
    null
  );

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAssignTagMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAssignTagMenuEl(null);
  };

  const handleAssignTag = (
    value: UserRoles,
    selectedId: number,
    itemName: string
  ) => {
    assignTagPopup(value, selectedId, itemName);
  };

  const handleExcludeTag = () => {
    setCallForceAudit(false);
    setDialogTitle(DialogMessage.Dialog_Title_Exclude_Tag);
    setDialogMessage(DialogMessage.Dialog_Message_Exclude_Tag);
    setInactiveDialogOpen(true);
  };

  const onForceAudit = () => {
    setCallForceAudit(true);
    setDialogTitle(DialogMessage.Dialog_Title_Force_Audit);
    setDialogMessage(DialogMessage.Dialog_Message_Force_Audit);
    setInactiveDialogOpen(true);
  };

  const onDialogClose = () => {
    setInactiveDialogOpen(false);
    setCallForceAudit(false);
    setDialogMessage("");
    setDialogTitle("");
  };

  const renderMenuItemWithTooltip = (
    text: string,
    tooltip: string,
    onClick: () => void,
    disableCondition: boolean
  ) => (
    <Tooltip
      title={isLocked ? tooltip : ""}
      placement="top"
      arrow
      disableHoverListener={!isLocked}
    >
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <MenuItem onClick={onClick} disabled={isLocked || disableCondition}>
          <div className={styles.menuName}>{text}</div>
          <Icon path={mdiChevronRight} size={0.9} />
        </MenuItem>
      </div>
    </Tooltip>
  );

  return (
    <Grid item container xs={12} className={styles.tagContainerInner}>
      <Grid item container xs={12} className={styles.tagContainerTop}>
        <Grid item container className={styles.tagName} sm={8} xs={12}>
          <ul>
            <li>
              <span className={styles.labelName}> Tag ID </span> #
              {tagNumber?.toString().padStart(2, "0")}
            </li>
            <li>
              <span className={styles.labelName}> {itemName} </span> #{itemId}
            </li>
          </ul>
        </Grid>
        <Grid item container className={styles.btnContainer} sm={4} xs={12}>
          {finalValue && (
            <div className={styles.numberoftag}>
              Final Value : {audit === null ? "-" : finalValue}
            </div>
          )}

          {!finalValue && auditorUserId && (
            <div className={styles.numberoftag}>
              Audit : {audit === null ? "-" : audit}
            </div>
          )}
          {!finalValue && counterUserId && (
            <div className={styles.numberoftag}>
              Count : {count === null ? "-" : count}
            </div>
          )}
          <IconButton onClick={handleMenuOpen} edge="end">
            <Icon path={mdiDotsVertical} size={1} />
          </IconButton>
          <Menu
            anchorEl={assignTagMenuEl}
            open={Boolean(assignTagMenuEl)}
            onClose={handleMenuClose}
            keepMounted
            className={styles.inventoryDropMenu}
            slotProps={{
              paper: {
                sx: {
                  width: "250px",
                },
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {renderMenuItemWithTooltip(
              "Assign Tag to Counter ",
              "The inventory is locked, you cannot edit this inventory.",
              () => handleAssignTag(UserRoles.Counter, tagId, itemName),
              count !== null
            )}
            {renderMenuItemWithTooltip(
              "Re-assign Tag to Auditor",
              "The inventory is locked, you cannot edit this inventory.",
              () => handleAssignTag(UserRoles.Auditor, tagId, itemName),
              auditorUserId === null ||
                (auditorUserId !== null && audit !== null)
            )}
            <MenuItem onClick={handleExcludeTag}>
              <div className={styles.menuName}>
                Exclude from the next inventory session
              </div>
              <Icon path={mdiChevronRight} size={0.9} />
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Grid item container xs={12} className={styles.tagContainerBottom}>
        <Grid item container xs={12} className={styles.listContainer}>
          <ul>
            <li>
              <span className={styles.labelName}> Description </span>
              <Tooltip title={description} placement="bottom" arrow>
                <span className={styles.tooltip_div}> {description} </span>
              </Tooltip>
            </li>
            <li>
              <span className={styles.labelName}> UOMs </span>
              {secondaryUOM && secondaryUOM !== "" ? secondaryUOM : primaryUOM}
            </li>
            <li>
              <span className={styles.labelName}> UOM </span> {primaryUOM}
            </li>
          </ul>

          {counterUserId && (
            <IconButton
              aria-label="clear selection"
              edge="end"
              className={styles.round_btn}
              onClick={() =>
                navigate(
                  `/inventory/tag-details?tagid=${tagId}&isLocked=${isLocked}`
                )
              }
            >
              <Icon path={mdiChevronRight} size={1.3} />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={12} className={styles.forceAudit}>
        {showForceAuditCheckBox && (
          <Tooltip
            title={
              isLocked
                ? "The inventory is locked, you cannot edit this inventory."
                : ""
            }
            placement="top"
            arrow
            disableHoverListener={!isLocked}
          >
            <FormControlLabel
              className={styles.forceAuditLabel}
              control={
                <Checkbox
                  checked={forceAudited}
                  onChange={() => onForceAudit()}
                  disabled={forceAudited || isLocked}
                />
              }
              label="Force Audit"
            />
          </Tooltip>
        )}
      </Grid>
      <ConfirmationDialog
        open={inactiveDialogOpen}
        onClose={() => onDialogClose()}
        onConfirm={() => handleOnConfirm()}
        message={dialogMessage}
        title={dialogTitle}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
export default InventoryDetailsTagTile;
