import React, { useState } from "react";
import styles from "./masters.module.scss";
import { ITabPanelProps } from "../../../interfaces";
import { Alert, Box, Grid, Snackbar, styled, Tab, Tabs } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Items, Locations, Uoms } from "../../";
import { MasterExport } from "../../../utils/Enum/MasterExport";
import MasterExportService from "../../../api/services/MasterExportService";

function TabPanel(props: ITabPanelProps) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      className={styles.tabpanel_container}
    >
      {value === index && (
        <Box className="tab_wrapper" component="div" sx={{ mt: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
const Masters: React.FC = () => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error" | "info">("info");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const CustomTabs = styled(Tabs)({
    "& .MuiTabs-flexContainer": {
      //borderBottom: "0.4px solid rgb(0, 0, 0)",
    },
    "& .MuiTabs-indicator": {
      height: "3px",
      marginBottom: "-1px",
      backgroundColor: "#115ACB",
    },
    ".MuiButtonBase-root": {
      borderRadius: "0px",
      padding: "16px",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: " 600",
    },
    "& .Mui-selected": {
      //backgroundColor: "rgba(24, 20, 243, 0.05) !important",
      color: "#115ACB !important",
    },
  });

  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();

  //Handling the change of data for Tabs
  const handleChange = (evet: React.SyntheticEvent, newValue: number) => {
    sessionStorage.setItem("ActiveTabIndex", JSON.stringify(newValue));
    setTabValue(newValue);
  };

  const onExportMastersData = async (value: MasterExport) => {
    const clientId = Number(localStorage.getItem("clientId"));
    let response;
    let filename;

    setSeverity("info");
    setSnackbarMessage("Please wait, your Excel is being downloaded.");
    setOpenSnackbar(true);

    try {
      switch (value) {
        case MasterExport.Items:
          response = await MasterExportService.exportItemDataToExcel(clientId);
          filename = 'ICM_Item.xlsx';
          break;
        case MasterExport.Locations:
          response = await MasterExportService.exportLocationDataToExcel(clientId);
          filename = 'ICM_Location.xlsx';
          break;
        case MasterExport.UOM:
          response = await MasterExportService.exportUOMDataToExcel(clientId);
          filename = 'ICM_UOM.xlsx';
          break;
        default:
          console.error('Invalid export type:', value);
          return;
      }

      if (response.isSuccess) {
        const base64Data = response.excelData;
        const binaryString = window.atob(base64Data);

        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes.buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setSeverity("success");
        setSnackbarMessage("Master Excel sheet has been downloaded successfully.");
        setOpenSnackbar(true);
      } else {
        const errorMessage =
          response.errors.join(", ") || " Master Excel sheet failed to downloaded.";
        setSeverity("error");
        setSnackbarMessage(errorMessage);
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSeverity("error");
      setSnackbarMessage("Master Excel sheet failed to downloaded.");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="content_container">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <h3 className={styles.page_title}>Masters</h3>
        </Grid>
      </Grid>
      <Grid container item className={styles.project_details_container}>
        <Grid item xs={12}>
          <CustomTabs
            value={tabValue}
            className={styles.tab_title}
            onChange={handleChange}
            //variant="scrollable"
            // scrollButtons
            // allowScrollButtonsMobile
            aria-label="basic tabs example"
          >
            <Tab key="0" label="Locations" />
            <Tab key="1" label="Items" />
            <Tab key="2" label="UOMs" />
          </CustomTabs>
          <TabPanel key="0" value={tabValue} index={0}>
            <Locations onExportMastersData={onExportMastersData}></Locations>
          </TabPanel>
          <TabPanel key="1" value={tabValue} index={1}>
            <Items onExportMastersData={onExportMastersData}></Items>
          </TabPanel>
          <TabPanel key="2" value={tabValue} index={2}>
            <Uoms onExportMastersData={onExportMastersData}></Uoms>
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
};

export default Masters;
