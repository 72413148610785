import { Dialog, styled } from "@mui/material";

export const DialogStyle = styled(Dialog)({
  "& .MuiPaper-root": {
    maxWidth: "730px",
    width: "730px",
    borderRadius: "0px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    alignContent: "flex-start",
    alignItem: "flex-start",
  },
  "& .MuiDialogTitle-root": {
    padding: "24px",
    background: "#082A5E",
    color: "#FFF",
    textAlign: "left",
    "@media screen and (max-width:768px)": {
      padding: "16px",
      fontSize: "18px",
    },
  },
  "& .MuiDialogContent-root": {
    padding: "15px 24px",
    paddingBottom: "25px",
    textAlign: "left",
  },
  "& .MuiDialogActions-root": {
    gap: "15px",
    padding: "45px 24px",
    paddingTop: "10px",
    "& button": {
      width: "auto",
    },
  },
});
export default DialogStyle;
