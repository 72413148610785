// TaskItem.tsx
import React, { Suspense, useCallback, useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  SelectChangeEvent,
  styled,
  Tab,
  Tabs,
} from "@mui/material";
import {
  CustomPagination,
  EmptyDashboard,
  FilterDropdown,
  SearchInput,
} from "../../../components";
import { AddTagDialog, Calculator, TagDetailsTile } from "../../../containers";
import styles from "./tagDetails.module.scss";
import {
  ITabPanelProps,
  IAreaWithLocations,
  ITagsByLocationRequest,
  ITagDetails,
} from "../../../interfaces";
import { tagService } from "../../../api/services";
import { useClient } from "../../../utils/ClientContext";

function TabPanel(props: ITabPanelProps) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      className={styles.tabpanel_container}
    >
      {value === index && (
        <Box className="tab_wrapper" component="div" sx={{ mt: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const TagDetails: React.FC = () => {
  const CustomTabs = styled(Tabs)({
    "& .MuiTabs-flexContainer": {
      //borderBottom: "0.4px solid rgb(0, 0, 0)",
    },
    "& .MuiTabs-indicator": {
      height: "3px",
      marginBottom: "-1px",
      backgroundColor: "#115ACB",
    },
    ".MuiButtonBase-root": {
      borderRadius: "0px",
      padding: "16px",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: " 600",
    },
    "& .Mui-selected": {
      //backgroundColor: "rgba(24, 20, 243, 0.05) !important",
      color: "#115ACB !important",
    },
  });

  const [tabValue, setTabValue] = useState(0);
  //Handling the change of data for Tabs
  const handleTabChange = (evet: React.SyntheticEvent, newValue: number) => {
    if (areaData != null) {
      setTabValue(newValue);
      localStorage.setItem(
        "locationId",
        areaData.locations[newValue].locationId.toString()
      );
    }
  };

  const [AddTagDialogOpen, setAddTagDialogOpen] = useState(false);
  const [searchKey, setSearchKey] = useState<string>("");
  const [uoms, setUoms] = useState<{ id: number; name: string }[]>([]);
  const [selectedValue, setSelectedValue] = useState<boolean | undefined>(
    undefined
  );
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const filterOptions = [
    { label: "Completed Tags", value: true },
    { label: "Incomplete Tags", value: false },
  ];
  const [areaData, setAreaData] = useState<IAreaWithLocations | null>(null);
  const [tagData, setTagData] = useState<ITagDetails[] | null>(null);
  const { client } = useClient();
  const clientId = client.id;
  const areaId = Number(localStorage.getItem("areaId"));
  const locationId = Number(localStorage.getItem("locationId"));
  const isCounter = localStorage.getItem("isCounting") === "true";
  const userId = Number(localStorage.getItem("userId"));
  const inventoryId = Number(localStorage.getItem("inventoryId"));
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalRecord, setTotalRecord] = React.useState(0);
  const [hasLocation, setHasLocation] = React.useState(false);
  const [noDataFound, setNoDataFound] = React.useState(false);

  interface LocationWithAreaRequestViewModel {
    areaId: number;
    clientId: number;
    inventoryId: number;
    userId: number;
  }

  // Handle change of selected value
  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    const selectedLabel = event.target.value;
    const selectedOption = filterOptions.find(
      (option) => option.label === selectedLabel
    );
    setSelectedValue(selectedOption ? selectedOption.value : undefined);
  };

  // Function to handle opening the filter dropdown
  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  // Function to handle closing the filter dropdown
  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  // Function to clear the filter
  const handleFilterClear = () => {
    setSelectedValue(undefined);
  };
  //Open Location Dialog
  const onClickAddTagDialogOpen = () => {
    setAddTagDialogOpen(true);
  };

  //Close Location Dialog
  const onClickAddTagDialogClose = useCallback(() => {
    setAddTagDialogOpen(false);
  }, []);

  // Create a state variable for the input value
  const [formValues, setFormValues] = useState({
    count: "",
    uom: "",
  });

  const [errors, setErrors] = useState({
    location: false,
    item: false,
    count: false,
    uom: false,
  });
  // Create an onChange handler
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value, // Use the name attribute to update the correct field
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === "", // Set error if field is empty
    }));
  };

  const fetchAreaWithLocation = async () => {
    try {
      const requestData: LocationWithAreaRequestViewModel = {
        areaId,
        clientId,
        inventoryId,
        userId,
      };
      const response = await tagService.getAreaWithLocationAsync(requestData);
      if (response.data.isSuccess) {
        setAreaData(response.data.areaDetails);
        setHasLocation(response.data.isSuccess);
        const locationIndex = response.data.areaDetails.locations.findIndex(
          (location: any) => location.locationId === locationId
        );
        if (locationIndex !== -1) {
          setTabValue(locationIndex);
        } else {
          console.log("Location not found.");
        }
      }
    } catch (error) {
      console.error("Error fetching area with location:", error);
    }
  };

  const fetchTagDetails = async () => {
    try {
      const request: ITagsByLocationRequest = {
        userId,
        clientId,
        locationId,
        inventoryId,
        isCounted: selectedValue,
        isCounter: isCounter,
        searchTerm: searchKey,
        pageSize: pageSize,
        currentPage: page + 1,
      };
      const response = await tagService.getTagDetailsAsync(request);
      if (response.data.isSuccess) {
        if (
          response.data.tagDetailsDashboardViewModels.length === 0 &&
          (searchKey.length >= 3 || selectedValue != null)
        ) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
        }
        setTotalRecord(response.data.totalRecord);
        setPageCount(Math.ceil(response.data.totalRecords / pageSize));
        setTagData(response.data.tagDetailsDashboardViewModels);
      } else {
        setNoDataFound(true);
        setTagData(null);
      }
    } catch (error) {
      setNoDataFound(true);
      setTagData(null);
      console.error("Error fetching tags:", error);
    }
  };

  const handleFirstPage = () => {
    setPage(0);
  };

  const handleLastPage = () => {
    setPage(pageCount - 1);
  };

  const handleNextPage = () => {
    if (page < pageCount - 1) {
      setPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    const storedFilter = localStorage.getItem("selectedFilterOption");
    if (storedFilter) {
      const selectedOption = filterOptions.find(
        (option) => option.value.toString() === storedFilter
      );
      setSelectedValue(selectedOption ? selectedOption.value : undefined);
    }
    fetchAreaWithLocation();
  }, [clientId]);

  useEffect(() => {
    if (hasLocation) {
      if (searchKey.length >= 3 || searchKey.length === 0) {
        fetchTagDetails();
      } else if (selectedValue !== undefined) {
        fetchTagDetails();
      }
    }
  }, [searchKey, selectedValue, page, tabValue, hasLocation]);

  return (
    areaData && (
      <div className="content_container">
        <Grid
          container
          item
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={8}>
            <h3 className={styles.page_title}>{areaData.areaName}</h3>
          </Grid>
          {isCounter && (
            <Grid item container xs={4} justifyContent="end">
              <Button variant="contained" onClick={onClickAddTagDialogOpen}>
                Add Tag
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container item className={styles.tabsContainer}>
          <Grid item xs={12} className={styles.tabsContainerheader}>
            <CustomTabs
              value={tabValue}
              className={styles.tab_title}
              onChange={handleTabChange}
              //variant="scrollable"
              // scrollButtons
              // allowScrollButtonsMobile
              aria-label="Location Tabs"
            >
              {areaData.locations.map((location, index) => (
                <Tab
                  key={location.locationId}
                  label={location.locationName}
                  value={index}
                />
              ))}
            </CustomTabs>
            <TabPanel key={locationId} value={tabValue} index={tabValue}>
              <Grid
                item
                container
                xs={12}
                className={styles.searchFilterContainer}
              >
                <Grid item sm={4} xs={6.5}>
                  <SearchInput searchKey={searchKey} onChange={setSearchKey} />
                </Grid>
                <Grid item sm={2} xs={4.5}>
                  <Suspense fallback={<div>Loading...</div>}>
                    {handleFilterOpen && (
                      <FilterDropdown
                        selectedValue={
                          selectedValue !== undefined
                            ? filterOptions.find(
                                (option) => option.value === selectedValue
                              )?.label || ""
                            : ""
                        }
                        options={filterOptions.map((option) => ({
                          value: option.label,
                          label: option.label,
                        }))}
                        onChange={handleFilterChange}
                        onClear={handleFilterClear}
                        onOpen={handleFilterOpen}
                        onClose={handleFilterClose}
                        filterOpen={filterOpen}
                      />
                    )}
                  </Suspense>
                </Grid>
              </Grid>

              {noDataFound ? (
                <EmptyDashboard title="" subtitle="No data found!" />
              ) : (
                tagData != null &&
                tagData.length > 0 && (
                  <Suspense fallback={<div>Loading...</div>}>
                    {tagData.map((tag) => (
                      <TagDetailsTile
                        key={tag.tagId}
                        tagNumber={tag.tagNumber}
                        tagId={tag.tagId.toString()}
                        areaName={tag.areaName}
                        locationName={tag.locationName}
                        itemId={tag.itemId}
                        itemCode={tag.itemCode}
                        description={tag.itemDescription}
                        primaryUOMDisplay={tag.primaryUOMName}
                        uomDisplay={tag.countUOMName}
                        uomId={tag.countUOMId}
                        tagQtyCount={tag.tagQty}
                        isReconciled={tag.isReconciled}
                        isAudited={tag.isAudited}
                        onSubmit={fetchTagDetails}
                      />
                    ))}
                    <CustomPagination
                      page={page}
                      pageCount={pageCount || 1}
                      handleFirstPage={handleFirstPage}
                      handleLastPage={handleLastPage}
                      handleNextPage={handleNextPage}
                      handlePreviousPage={handlePreviousPage}
                    />
                  </Suspense>
                )
              )}
            </TabPanel>
          </Grid>
        </Grid>
        <Suspense fallback={<div>Loading...</div>}>
          {AddTagDialogOpen && (
            <AddTagDialog
              open={AddTagDialogOpen}
              onClose={onClickAddTagDialogClose}
              onSubmit={fetchTagDetails}
            />
          )}
        </Suspense>
      </div>
    )
  );
};

export default TagDetails;
