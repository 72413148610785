import ICMAxios from "../ICMAxios";

const getInventorySetting = async(clientId : Number)=>{
    const response = await ICMAxios.get(`InventorySettings/GetInventorySettingAsync/${clientId}`);
    return response.data;
}

const addInventorySetting = async(settingData: any)=>{
    const response = await ICMAxios.post(`InventorySettings/AddInventorySettingAsync`, settingData);
    return response.data;
}

const updateInventorySetting = async(settingData: any)=>{
    const response = await ICMAxios.put(`InventorySettings/UpdateInventorySettingAsync`, settingData);
    return response.data;
}

const adminSettingService = { getInventorySetting, addInventorySetting, updateInventorySetting }

export default adminSettingService;