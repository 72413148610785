import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  DialogTitle,
  IconButton,
  InputLabel,
  Autocomplete,
  InputAdornment,
  Grid,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiClose } from "@mdi/js";
import { IInactiveDialogProps } from "../../interfaces/IInactiveDialogProps";
import { InactiveDialogStyle } from "./InactiveDialogStyle";

const InactiveDialog: React.FC<IInactiveDialogProps> = ({ open, onClose, onConfirm , title ,message }) => {
  const onClickInactiveDialogClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const onClickInactiveDialogSubmit = () => {
    if (onConfirm) {
      onConfirm();
    }
    onClickInactiveDialogClose();
  };
  return (
    <InactiveDialogStyle open={open}>
      <IconButton
        aria-label="close"
        onClick={onClickInactiveDialogClose}
        sx={() => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: "#333",
        })}
      >
        <Icon path={mdiClose} size={1} />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} className="inactive_dialog_content">
            <img
              src={process.env.PUBLIC_URL + "/assets/Inactive_dialog_img.svg"}
              alt="Blank Screen Img"
            />
             <h3>{title ? title : "Mark Status as Inactive?"}</h3>
            <h5>
            {message}
            </h5>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="outlined"
          onClick={onClickInactiveDialogClose}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={onClickInactiveDialogSubmit}
        >
          Confirm
        </Button>
      </DialogActions>
    </InactiveDialogStyle>
  );
};
export default InactiveDialog;
