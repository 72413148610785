import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const instrumentationKey = process.env.REACT_APP_INSTRUMENTATION_KEY;

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
  }
});

appInsights.loadAppInsights();
appInsights.trackPageView();

export default appInsights;