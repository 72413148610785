import { IGetAssigneeByRoleAndClientParams, IUpdateAssigneeByRoleAndClientParams } from '../../interfaces';
import ICMAxios from '../ICMAxios';

const getActiveInventorySessionAsync = async (clientId: Number) => {
    const response = await ICMAxios.get(`/Inventory/GetActiveInventorySessionAsync/${clientId}`);
    return response.data;
}

const createInventoryAsync = async (formData: any) => {
    const response = await ICMAxios.post(`/Inventory/CreateInventoryAsync`, formData);
    return response.data;
}

const updateTagForceAudit = async (data: any) => {
    const response = await ICMAxios.put(`Tag/UpdateForceAudit`, data)
    return response.data;
}
const excludeTagFromNextInventory = async (data : any) =>{
    const response = await ICMAxios.put(`Tag/ExcludeTag`,data)
    return response.data;
}

const lockInventoryAsync = async (requestFormData: any) => {
    const response = await ICMAxios.put(`/Inventory/LockInventoryAsync`, requestFormData);
    return response.data;
}

const getRecentlyLockedInventorySessionAsync = async (clientId: Number) => {
    const response = await ICMAxios.get(`/Inventory/GetRecentlyLockedInventorySessionAsync/${clientId}`);
    return response.data;
}

const getAllInventorySessionAsync = async (requestViewModel: any) => {
    const response = await ICMAxios.post(`/Inventory/GetAllInventorySessionAsync`, requestViewModel);
    return response.data;
};

const copyInventoryAsync = async (requestViewModel: any) => {
    const response = await ICMAxios.post(`/Inventory/CopyInventoryAsync`, requestViewModel);
    return response.data;
};

const getAssigneeByRoleAndClientAsync = async (requestViewModel: IGetAssigneeByRoleAndClientParams) => {
    const response = await ICMAxios.put(`/Inventory/GetAssigneeByRoleAndClientAsync`, requestViewModel);
    return response.data;
};

const updateTagAssigneesAsync = async (requestViewModel: IUpdateAssigneeByRoleAndClientParams) => {
    const response = await ICMAxios.put(`/Inventory/UpdateTagAssigneesAsync`, requestViewModel);
    return response.data;
};

const exportTagsToExcelAsync = async (inventoryId: number) => {
    const response = await ICMAxios.get(`/Inventory/ExportTagsToExcel/${inventoryId}`);
    return response.data;
};

const getClientInventoriesByClientIdAsync = async (cleintId: number) => {
    const response = await ICMAxios.get(`/Inventory/GetClientInventoriesByClientIdAsync/${cleintId}`);
    return response.data;
};

const inventoryService = { getActiveInventorySessionAsync, createInventoryAsync, updateTagForceAudit, excludeTagFromNextInventory, lockInventoryAsync, getRecentlyLockedInventorySessionAsync, getAllInventorySessionAsync, copyInventoryAsync, getAssigneeByRoleAndClientAsync, updateTagAssigneesAsync, exportTagsToExcelAsync, getClientInventoriesByClientIdAsync }
export default inventoryService;