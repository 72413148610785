// api/axios.js
import axios, { AxiosRequestConfig } from "axios";


const ICMAxios = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  timeout: 10000 * 5,
  headers: {
    "Content-Type": "application/json",
  },
});

ICMAxios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    const clientId = Number(localStorage.getItem("clientId"));
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    if (clientId) {
      config.headers["ClientId"] = clientId.toString();
    }

      if (config.data instanceof FormData) {
        delete config.headers["Content-Type"];
      }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ICMAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // handle the response error
    if (error.response.status === 401) {
      // handle unauthorized access, you may want to redirect to login page
      console.error("Unauthorized access");
    }
    else {
      // You can even send the error information to a logging service.
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error);
  }
);

const httpService = {
  get: <T>(url: string, config?: AxiosRequestConfig): Promise<T> =>
  ICMAxios.get<T>(url, config).then((res) => res.data),

  post: <T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> =>
  ICMAxios.post<T>(url, data, config).then((res) => res.data),

  put: <T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> =>
  ICMAxios.put<T>(url, data, config).then((res) => res.data),

  delete: <T>(url: string, config?: AxiosRequestConfig): Promise<T> =>
  ICMAxios.delete<T>(url, config).then((res) => res.data),
};

export default ICMAxios;
