import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  TextField,
} from "@mui/material";
import styles from "./profile.module.scss";
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiClose,
  mdiEyeOffOutline,
  mdiEyeOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import ChangePasswordDialog from "./ChangePasswordDialog/ChangePasswordDialog";
import { userService } from "../../api/services";
import { useClient } from "../../utils/ClientContext";

const Profile: React.FC = () => {
  const [ChangePasswordDialogOpen, setChangePasswordDialogOpen] =
    React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const userId = localStorage.getItem("userId");
  const [isEditable, setIsEditable] = useState(false);
  const { setUserUpdated } = useClient();
  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  //Open Location Dialog
  const onClickChangePasswordDialogOpen = () => {
    setChangePasswordDialogOpen(true);
  };

  //Close Location Dialog
  const onClickChangePasswordDialogClose = useCallback(() => {
    setChangePasswordDialogOpen(false);
  }, []);

  // Create a state variable for the input value
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    emailAddress: false,
    password: false,
  });

  // Create an onChange handler
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const isInvalid = value.trim().length < 2;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value, // Use the name attribute to update the correct field
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: isInvalid,
    }));
  };

  useEffect(() => {
    fetchUser();
  }, []);
  const fetchUser = async () => {
    try {
      const response = await userService.fetchUserById(userId);
      setFormValues({
        firstName: response.firstName || "",
        lastName: response.lastName || "",
        emailAddress: response.email || "",
        password: "*".repeat(8),
      });
    } catch (error) {
      console.error("Error fetching clients", error);
      setSnackbarMessage("Failed to get User details");
      setSeverity("error");
      setOpenSnackbar(true);
    }
  };
  const handleEditButtonClick = async () => {
    if (isEditable) {
      try {
        const payload = {
          userId: userId,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          modifiedBy: userId,
        };
        const response = await userService.updateProfile(payload);
        if (response.isSuccess) {
          setSnackbarMessage("Profile updated successfully!");
          setSeverity("success");
          setOpenSnackbar(true);
          setUserUpdated(true);
          setTimeout(() => {
            handleCloseSnackbar();
          }, 1000);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
          }));
        }
      } catch (error) {
        console.error("Error updating profile", error);
        setSnackbarMessage("Failed to update profile");
        setSeverity("error");
        setOpenSnackbar(true);
      }
    }
    // Toggle edit mode off after saving
    setIsEditable((prev) => !prev);
  };
  return (
    <div className="content_container">
      <Grid container item spacing={2}>
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <h3 className={styles.page_title}>My Profile</h3>
          <Button
            variant="contained"
            onClick={handleEditButtonClick}
            disabled={
              isEditable &&
              (errors.firstName ||
                errors.lastName ||
                !formValues.firstName.trim() ||
                !formValues.lastName.trim())
            }
          >
            {" "}
            {isEditable ? "Save" : "Edit"}
          </Button>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid container item sm={10} xs={12}>
          <Grid container item spacing={2}>
            <Grid item sm={6} xs={12}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="firstName"
                  name="firstName"
                  disabled={!isEditable}
                  value={formValues.firstName}
                  label="First Name"
                  placeholder="First Name"
                  onChange={handleChange}
                  error={errors.firstName}
                  helperText={
                    errors.firstName
                      ? "First name is required and must be at least 2 characters long."
                      : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="lastName"
                  name="lastName"
                  disabled={!isEditable}
                  value={formValues.lastName}
                  label="Last Name"
                  placeholder="Last Name"
                  onChange={handleChange}
                  error={errors.lastName}
                  helperText={
                    errors.lastName
                      ? "Last name is required and must be at least 2 characters long."
                      : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="emailAddress"
                  name="emailAddress"
                  type="email"
                  disabled
                  value={formValues.emailAddress}
                  label="Email Address"
                  placeholder="Email Address"
                  onChange={handleChange}
                  error={errors.emailAddress}
                  helperText={errors.emailAddress ? "Email is " : ""}
                />
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="password"
                  name="password"
                  disabled
                  type={showPassword ? "text" : "password"}
                  value={formValues.password}
                  label="Password"
                  placeholder="Enter Your Password"
                />
                <Link
                  onClick={onClickChangePasswordDialogOpen}
                  className={styles.changePassword}
                >
                  Change Password ?
                </Link>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ChangePasswordDialog
        open={ChangePasswordDialogOpen}
        onClose={onClickChangePasswordDialogClose}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default Profile;
