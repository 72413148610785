// src/contexts/ClientContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";
import { IClient, IClientContextProps } from "../interfaces";


const ClientContext = createContext<IClientContextProps | undefined>(undefined);

interface ClientProviderProps {
  children: ReactNode;
}

export const ClientProvider: React.FC<ClientProviderProps> = ({ children }) => {
  const [client, setClientState] = useState<IClient>({
    id: Number(localStorage.getItem("clientId")) || 0, 
    name: localStorage.getItem("clientName") || "",
  });
  const [userUpdated, setUserUpdated] = useState(false);
  const setClient = (client: IClient) => {
    setClientState(client);
    localStorage.setItem("clientId", client.id.toString());  
    localStorage.setItem("clientName", client.name);
  };

  return (
    <ClientContext.Provider value={{ client, setClient,userUpdated, setUserUpdated  }}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = (): IClientContextProps => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error("useClient must be used within a ClientProvider");
  }
  return context;
};
