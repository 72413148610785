import React from "react";

export { default as Login } from "./Login/Login";
export { default as Signup } from "./Signup/Signup";
export { default as ForgetPassword } from "./ForgetPassword/ForgetPassword";
export { default as Dashboard } from "./Dashboard/Dashboard";
export { default as SettingsMaster } from "./SettingsMaster/SettingsMaster";
export { default as Settings } from "./SettingsMaster/Settings/Settings";
export { default as Masters } from "./SettingsMaster/Masters/Masters";
export { default as Locations } from "./SettingsMaster/Masters/Locations/Locations";
export { default as Items } from "./SettingsMaster/Masters/Items/Items";
export { default as Uoms } from "./SettingsMaster/Masters/Uoms/Uoms";
export { default as InventoryMaster } from "./Inventory/InventoryMaster";
export { default as Inventory } from "./Inventory/Inventory";
export { default as AddInventoryDialog } from "./Inventory/AddInventoryDialog/AddInventoryDialog";
export { default as UserStateCount } from "./Inventory/UserStateCount/UserStateCount";
export { default as UserStateAudit } from "./Inventory/UserStateAudit/UserStateAudit";
export { default as Mat2rix } from "./Mat2rix/Mat2rix";
export { default as Users } from "./Users/Users";
export { default as Profile } from "./Profile/Profile";
export { default as UsersDashboard } from "./UsersDashboard/UsersDashboard";
export { default as TagDetails } from "./UsersDashboard/TagDetails/TagDetails";

export const CountingTask = React.lazy(
  () => import("./UsersDashboard/CoutingTask/CoutingTask")
);
export const AddTagDialog = React.lazy(
  () => import("./UsersDashboard/AddTagDialog/AddTagDialog")
);
export const Calculator = React.lazy(
  () => import("./UsersDashboard/AddTagDialog/Calculator/Calculator")
);
export const AddUserDialog = React.lazy(
  () => import("./Users/AddUserDialog/AddUserDialog")
);

export const TagDetailsTile = React.lazy(
  () => import("./UsersDashboard/TagDetails/TagDetailsTile/TagDetailsTile")
);
export const ImportSheetDialog = React.lazy(
  () => import("./SettingsMaster/Masters/ImportSheetDialog/ImportSheetDialog")
);
export const AddLocationDialog = React.lazy(
  () =>
    import(
      "./SettingsMaster/Masters/Locations/AddLocationDialog/AddLocationDialog"
    )
);
export const AddClientDialog = React.lazy(
  () => import("./Mat2rix/AddClientDialog/AddClientDialog")
);
export const AddItemDialog = React.lazy(
  () => import("./SettingsMaster/Masters/Items/AddItemDialog/AddItemDialog")
);

export const AddUomsDialog = React.lazy(
  () => import("./SettingsMaster/Masters/Uoms/AddUomsDialog/AddUomsDialog")
);
export { default as AdminSetting } from "./SettingsMaster/Settings/AdminSetting/AdminSetting";
export const InventoryDetails = React.lazy(
  () => import("./Inventory/InventoryDetails/InventoryDetails")
);

export { default as InventoryDetailsTagTile } from "./Inventory/InventoryDetails/InventoryDetailsTagTile/InventoryDetailsTagTile";
export { default as ReconciliationTagTile } from "./Inventory/ReconciliationTagTile/ReconciliationTagTile";

export const CreateCopyInventoryDialog = React.lazy(
  () =>
    import("./Inventory/CreateCopyInventoryDialog/CreateCopyInventoryDialog")
);

export const CopyInventoryDialog = React.lazy(
  () => import("./Inventory/CopyInventoryDialog/CopyInventoryDialog")
);

export const AssignTagDialog = React.lazy(
  () => import("./Inventory/AssignTagDialog/AssignTagDialog")
);
export { default as ConfirmPassword } from "./ConfirmPassword/ConfirmPassword";
