import * as React from "react";
import styles from "./routes.module.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { IRole, IRouteConfig } from "../interfaces";
import { BreadcrumbsComponent, Header, NavigationBar } from "../components";
import {
  mdiAccountMultipleOutline,
  mdiCogOutline,
  mdiHome,
  mdiSpeedometer,
} from "@mdi/js";
import { useDrawer } from "../utils/DrawerContext";
import {
  AdminSetting,
  ConfirmPassword,
  Dashboard,
  ForgetPassword,
  Inventory,
  InventoryDetails,
  InventoryMaster,
  Login,
  Masters,
  Mat2rix,
  Profile,
  ReconciliationTagTile,
  Settings,
  SettingsMaster,
  Signup,
  TagDetails,
  Users,
  UsersDashboard,
} from "../containers";
import { useEffect } from "react";
import ProtectedRoute from "./protectedRoute";
import { ModalProvider, IdleTimer } from "../utils";
import CustomModal from "../components/Modal/Modal";
import { UserRoles } from "../utils/Enum/UserRole";
import { useClient } from "../utils/ClientContext";

const AppRoutes: React.FC = () => {
  const routes: IRouteConfig[] = [
    {
      path: "/",
      component: Mat2rix,
      title: "",
      icon: mdiHome,
      menuName: "Home",
      isShow: true,
      isMat2rix: true,
      protected: true,
      roles: [],
    },
    {
      path: "/ICM",
      component: Dashboard,
      title: "",
      icon: mdiSpeedometer,
      menuName: "Dashboard",
      isMat2rix: false,
      isShow: true,
      protected: true,
      roles: [UserRoles.SuperAdmin, UserRoles.Admin],
    },
    {
      path: "/users",
      component: Users,
      title: "",
      icon: mdiAccountMultipleOutline,
      menuName: "Users",
      isShow: true,
      isMat2rix: true,
      protected: true,
      roles: [UserRoles.SuperAdmin, UserRoles.Admin],
    },
    {
      path: "/my-profile",
      component: Profile,
      title: "",
      icon: mdiAccountMultipleOutline,
      menuName: "My Profile",
      isShow: false,
      isMat2rix: true,
      protected: true,
      roles: [],
    },
    {
      path: "/login",
      component: Login,
      title: "",
      icon: mdiAccountMultipleOutline,
      menuName: "Login",
      isShow: false,
      protected: true,
      roles: [],
    },
    {
      path: "/signup",
      component: Signup,
      title: "",
      icon: mdiAccountMultipleOutline,
      menuName: "signup",
      isShow: false,
      protected: false,
      roles: [],
    },
    {
      path: "/forget-password",
      component: ForgetPassword,
      title: "",
      icon: mdiAccountMultipleOutline,
      menuName: "Forget Passowrd",
      isShow: false,
      protected: false,
      roles: [],
    },
    {
      path: "/reset-password",
      component: ConfirmPassword,
      title: "",
      icon: mdiAccountMultipleOutline,
      menuName: "Reset Passowrd",
      isShow: false,
      protected: false,
      roles: [],
    },
    {
      path: "/user-dashboard",
      component: UsersDashboard,
      title: "",
      icon: mdiAccountMultipleOutline,
      menuName: "Dashboard",
      isShow: true,
      protected: true,
      roles: [UserRoles.Auditor, UserRoles.Counter, UserRoles.SuperAdmin],
    },
    {
      path: "/tag-details",
      component: TagDetails,
      title: "",
      icon: mdiCogOutline,
      menuName: "Tag Details",
      isShow: false,
      protected: true,
      roles: [UserRoles.Auditor, UserRoles.Counter, UserRoles.SuperAdmin],
    },
    {
      path: "/settings",
      component: SettingsMaster,
      title: "",
      icon: mdiCogOutline,
      menuName: "Settings",
      isShow: true,
      protected: true,
      roles: [UserRoles.SuperAdmin, UserRoles.Admin],
      children: [
        {
          path: "/settings",
          component: Settings,
          title: "",
          icon: mdiCogOutline,
          menuName: "Settings",
          isShow: false,
          protected: true,
          roles: [UserRoles.SuperAdmin, UserRoles.Admin],
        },
        {
          path: "/settings/masters",
          component: Masters,
          title: "",
          icon: mdiSpeedometer,
          menuName: "Masters",
          isShow: false,
          protected: true,
          roles: [UserRoles.SuperAdmin, UserRoles.Admin],
        },
        {
          path: "/settings/inventory-settings",
          component: AdminSetting,
          title: "",
          icon: mdiSpeedometer,
          menuName: "Inventory Settings",
          isShow: false,
          protected: true,
          roles: [UserRoles.SuperAdmin, UserRoles.Admin],
        },
      ],
    },
    {
      path: "/inventory",
      component: InventoryMaster,
      title: "",
      icon: mdiAccountMultipleOutline,
      menuName: "Inventory",
      isShow: false,
      protected: true,
      roles: [UserRoles.SuperAdmin, UserRoles.Admin],
      children: [
        {
          path: "/inventory",
          component: Inventory,
          title: "",
          icon: mdiAccountMultipleOutline,
          menuName: "Inventory",
          isShow: false,
          protected: true,
          roles: [UserRoles.SuperAdmin, UserRoles.Admin],
        },
        {
          path: "/inventory/tag-details",
          component: ReconciliationTagTile,
          title: "",
          icon: mdiCogOutline,
          menuName: "Tag Details",
          isShow: false,
          protected: true,
          roles: [UserRoles.SuperAdmin, UserRoles.Admin],
        },
      ],
    },
  ];

  return (
    <ModalProvider>
      <IdleTimer timeout={20 * 60 * 1000} />
      <Router>
        <MainLayout routes={routes} />
      </Router>
      <CustomModal />
    </ModalProvider>
  );
};

const MainLayout: React.FC<{ routes: IRouteConfig[] }> = ({ routes }) => {
  const { client } = useClient();
  const clientId = client.id;
  const token = localStorage.getItem("token");

  const location = useLocation(); // Get the current location
  const isLoginPage =
    location.pathname === "/login" ||
    location.pathname === "/signup" ||
    location.pathname === "/forget-password" ||
    location.pathname === "/reset-password"; // Check if current path is login
  const isMat2rixPage =
    location.pathname === "/" ||
    location.pathname === "/user-dashboard" ||
    location.pathname === "/ICM"; // Check if current path is login

  const { drawerOpen } = useDrawer();

  useEffect(() => {
    // Force DataGrid to resize when drawer state changes
    window.dispatchEvent(new Event("resize"));
  }, [drawerOpen]);

  // Recursive function to render routes, including children
  const renderRoutes = (route: IRouteConfig) => {
    if (!clientId && token && !(location.pathname === '/my-profile')) {
      return null;
    }
    if (route.children && route.children.length > 0) {
      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            route.protected ? (
              <ProtectedRoute allowedRoles={route.roles}>
                <route.component pageTitle={route.title} />
              </ProtectedRoute>
            ) : (
              <route.component pageTitle={route.title} />
            )
          }
        >
          {route.children.map((childRoute) => renderRoutes(childRoute))}
        </Route>
      );
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          route.protected ? (
            <ProtectedRoute allowedRoles={route.roles}>
              <route.component pageTitle={route.title} />
            </ProtectedRoute>
          ) : (
            <route.component pageTitle={route.title} />
          )
        }
      />
    );
  };

  return (
    <div className={styles.layout}>
      {!isLoginPage && <Header />} {/* Conditionally render Header */}
      <div className={`${styles.content} + content_with_margin`}>
        {!isLoginPage && <NavigationBar routes={routes} />}
        {/* Conditionally render NavigationBar */}
              <div
                  className={`${styles.contentInner} ${drawerOpen ? styles.drawerOpenClass : styles.drawerClosedClass

                      }  ${isLoginPage ? styles.loginPageClass : ""}`}
              >
          {!isLoginPage && !isMat2rixPage && (
            <BreadcrumbsComponent routes={routes} />
          )}{" "}
          {/* Conditionally render Breadcrumbs */}
          <Routes>{routes.map((route) => renderRoutes(route))}</Routes>
        </div>
      </div>
    </div>
  );
};

export default AppRoutes;
