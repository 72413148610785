import ICMAxios from "../ICMAxios";
import { ITagDetailsByUserRequest, ITagsByLocationRequest, IUpdateReconcileDetailsRequest } from "../../interfaces";

const TagService = {
    // Add a new tag
    addTagAsync: (tagRequest: any) =>
        ICMAxios.post('/Tag/AddTagAsync', tagRequest),

    // Get tags by user ID
    getTagByUserIdAsync: (requestViewModel: ITagDetailsByUserRequest) =>
        ICMAxios.post('/Tag/GetTagByUserIdAsync', requestViewModel),

    // Get tag detailsAnyNaptrRecord by location ID
    getTagDetailsAsync: (requestViewModel: ITagsByLocationRequest) =>
        ICMAxios.post('/Tag/GetTagDeatilsAsync', requestViewModel),

    // Update an existing tag
    updateTagAsync: (updateTagsRequestView: any) =>
        ICMAxios.put('/Tag/UpdateTagAsync', updateTagsRequestView),

    // Fetch items by client ID
    getItemsByClientIdAsync: (clientId: number) =>
        ICMAxios.get(`/Item/GetItemsTagAsync/${clientId}`),

    // Fetch UOM tags by item ID
    getItemUOMAsync: (itemId: number) =>
        ICMAxios.get(`/Item/GetItemUOMTagsAsync/${itemId}`),

    // Fetch locations by area ID
    getAreaWithLocationAsync: (locationWithAreaRequestViewModel: any) =>
        ICMAxios.post(`/Location/GetAreaWithLocationByIdAsync`, locationWithAreaRequestViewModel),

    // Fetch locations by client ID
    getLocationsByClientIdAsync: (clientId: number) =>
        ICMAxios.get(`/Location/GetLocationsAsync/${clientId}`),

        
    getActiveAreasWithPaginationAsync: (clientId: number, pageIndex: number, pageSize: number, doTagsNeedAttention: boolean, hasCountTags: boolean, hasAuditTags: boolean, searchText: string | null, inventoryId:number) =>
        ICMAxios.post('/Inventory/GetActiveAreasWithPaginationAsync', {
            pageIndex,
            pageSize,
            searchText,
            doTagsNeedAttention,
            hasCountTags,
            hasAuditTags,
            clientId,
            inventoryId
        }),

    getActiveLocationsWithPaginationAsync: (areaId: number, pageIndex: number, pageSize: number, doTagsNeedAttention: boolean, hasCountTags: boolean, hasAuditTags: boolean, searchText: string | null, inventoryId:number) =>
        ICMAxios.post('/Inventory/GetActiveLocationsWithPaginationAsync', {
            pageIndex,
            pageSize,
            searchText,
            doTagsNeedAttention,
            hasCountTags,
            hasAuditTags,
            areaId,
            inventoryId
        }),

    getActiveTagsWithPaginationAsync: (locationId: number, pageIndex: number, pageSize: number, doTagsNeedAttention: boolean, hasCountTags: boolean, hasAuditTags: boolean, searchText: string | null, inventoryId: number) =>
        ICMAxios.post('/Inventory/GetActiveTagsWithPaginationAsync', {
            locationId,
            pageIndex,
            pageSize,
            doTagsNeedAttention,
            hasCountTags,
            hasAuditTags,
            searchText,
            inventoryId,
    }),

    getReconciliationTagAsync: (tagId: number) =>
        ICMAxios.get(`/Inventory/GetReconciliationTagAsync/${tagId}`),

    updateReconcileDetailsRequest: (requestViewModel: IUpdateReconcileDetailsRequest) =>
        ICMAxios.put('/Tag/UpdateReconcileDetailsAsync', requestViewModel),
};

export default TagService;
