import { Dialog, styled } from "@mui/material";

export const InactiveDialogStyle = styled(Dialog)({
  "& .MuiPaper-root": {
    maxWidth: "600px",
    width: "600px",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    alignContent: "center",
    alignItem: "center",
  },
  "& .MuiDialogTitle-root": {
    padding: "40px",
    background: "#082A5E",
    color: "#FFF",
    textAlign: "left",
  },
  "& .MuiDialogContent-root": {
    padding: "20px",
    paddingTop: "40px",
    textAlign: "center",
    // maxWidth: "80%",
    // margin: "auto",
  },
  "& .MuiDialogActions-root": {
    gap: "15px",
    padding: "24px",
    paddingTop: "10px",
    justifyContent: "end",
    "& button": {
      width: "auto",
    },
  },
});
export default InactiveDialogStyle;
