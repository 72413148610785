import { createContext, useState, useContext } from 'react';

interface ModalContextProps {
    isOpen: any;
    message: any;
    openModal: any;
    closeModal: any;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');

    const openModal = (msg: any) => {
        setMessage(msg);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        setMessage('');
    };

    return (
        <ModalContext.Provider value={{ isOpen, message, openModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    );
};
