import React from "react";
import { DialogContent, Grid, styled, Dialog } from "@mui/material";
import { INoAccessDialogProps } from "../../../interfaces";
import Icon from "@mdi/react";
import { mdiLock } from "@mdi/js";
import { Margin } from "@mui/icons-material";
import { useAuth } from "../../../utils";
import { useNavigate } from "react-router-dom";

const NoAccessDialog: React.FC<INoAccessDialogProps> = ({
  open,
  onClose,
  showHome,
}) => {
  const clientId = Number(localStorage.getItem("clientId"));
  const userId = Number(localStorage.getItem("userId"));
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  // Close inventory dialog
  const onClickAddInventoryDialogClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const DialogStyle = styled(Dialog)({
    "& .MuiPaper-root": {
      maxWidth: "730px",
      width: "730px",
      borderRadius: "8px",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      alignContent: "center",
      alignItem: "center",
      justifyContent: "center",
      background: "#F0F1F8",
    },
    "& .MuiDialogContent-root": {
      padding: "15px 24px",
      paddingBottom: "25px",
      alignContent: "center",
      alignItem: "center",
      justifyContent: "center",
      background: "#F0F1F8",
    },
    "& .MuiDialogActions-root": {
      gap: "15px",
      padding: "45px 24px",
      paddingTop: "10px",
      "& button": {
        width: "auto",
      },
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.2)", // Semi-transparent black
      backdropFilter: "blur(3px)", // Blur effect
    },
    "& .noAccessContent": {
      alignContent: "center",
      alignItem: "center",
      justifyContent: "center",
      minHeight: "70vh",
      gap: "15px",
      textAlign: "center",
      "& .noAccessIcon": {
        width: "80px",
        height: "80px",
        background: "#D9DDE8",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& h3": {
        width: "100%",
        margin: "0px",
      },
      "& p": {
        margin: "0px",
        fontSize: "16px",
        fontWeight: "500",
      },
    },
  });
  return (
    <DialogStyle open={open}>
      <DialogContent>
        <Grid container>
          <Grid item container sm={12} className="noAccessContent">
            <div className="noAccessIcon">
              <Icon path={mdiLock} size={2} />
            </div>
            <h3>Unable to access</h3>
            <p>
              You cannot access this module. Please contact the administrator to
              enable your access.
            </p>
            {showHome ? (
              <a href="/">Go to Home</a>
            ) : (
              <a href="/login" className="logoutLink" onClick={handleLogout}>
                Log out
              </a>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </DialogStyle>
  );
};

export default NoAccessDialog;
