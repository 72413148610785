import UserAxios from "../ICMAxios";

const getItemsByClient =async(ClientId : Number , CurrentPage: number )=>{
    const response = await UserAxios.get(`Item/${ClientId}?CurrentPage=${CurrentPage}`);
    return response.data;
}
const addItems =async(formData: any)=>{
    const response = await UserAxios.post(`Item/AddItem`, formData);
    return response.data;
}

const updateItem = async(formData: any)=>{
    const response = await UserAxios.put(`Item/update`, formData);
    return response.data;
}
const updateItemStatus = async(formData: any)=>{
    const response = await UserAxios.put(`Item/status`, formData);
    return response.data;
}
const itemService = {getItemsByClient, addItems,updateItem, updateItemStatus}

export default itemService;