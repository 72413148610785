import React, { Suspense, useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  SelectChangeEvent,
  Snackbar,
} from "@mui/material";
import styles from "./users.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ActiveSwitch,
  CustomPagination,
  Datatable,
  EmptyDashboard,
  FilterDropdown,
  InactiveDialog,
  SearchInput,
} from "../../components";
import { GridColDef, GridColumnGroupingModel } from "@mui/x-data-grid";
import { AddUserDialog } from "..";
import { userService } from "../../api/services";
import { useClient } from "../../utils/ClientContext";
import { IUserList } from "../../interfaces";
import { UserRoles } from "../../utils/Enum/UserRole";
import { mdiAlertCircleOutline, mdiCheck, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { DialogMessage } from "../../utils/Enum/DialogMessage";
import { ActiveSwitchStyled } from "../../components/ActiveSwitch/ActiveSwitch";

const Users: React.FC = () => {
  const navigate = useNavigate();

  const handleAdminNavigation = () => {
    navigate("/settings", { replace: true });
  };

  const handleMasterNavigation = () => {
    navigate("/settings/masters", { replace: true });
  };

  const location = useLocation(); // Get the current location

  // Check if the current route is exactly "/settings"
  const [searchKey, setSearchKey] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string | undefined>("");
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [emptyState, setEmptyState] = React.useState(false);

  // Handle change of selected value
  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    const roleValue = event.target.value;
    setSelectedRoleValue(roleValue);
  };
  // Open the filter dropdown
  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  // Close the filter dropdown
  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  // Clear the selected value
  const handleFilterClear = () => {
    setSelectedRoleValue("");
  };

  //Open Location Dialog
  const onClickAddUserDialogOpen = () => {
    setAddUserDialogOpen(true);
  };

  //Close Location Dialog
  const onClickAddUserDialogClose = useCallback(() => {
    setAddUserDialogOpen(false);
  }, []);
  const roleOptions = [
    { value: String(UserRoles.Admin), label: "Admin" },
    { value: String(UserRoles.Auditor), label: "Auditor" },
    { value: String(UserRoles.Counter), label: "Counter" },
  ];
  const [AddUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = React.useState(0);
  const [error, setError] = useState<string | null>(null);
  const [users, setUsers] = useState<IUserList>();
  const [pageSize, setPageSize] = React.useState(10);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalRecord, setTotalRecord] = React.useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);
  const { client } = useClient();
  const clientId = client.id;
  const loggedInUser = Number(localStorage.getItem("userId"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };
  //Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Handles the page change event
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  const handleFirstPage = () => {
    setPage(0);
  };

  const handleLastPage = () => {
    setPage(pageCount - 1);
  };

  const handleNextPage = () => {
    if (page < pageCount - 1) {
      setPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prev) => prev - 1);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      minWidth: 200,
      flex: 1.2,
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      width: 130,
      minWidth: 130,
      flex: 1.2,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 130,
      minWidth: 130,
      flex: 1.2,
    },
    {
      field: "isAdmin",
      headerName: "Admin",
      width: 80,
      minWidth: 80,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const isChecked = params.row.isAdmin;
        return (
          <Checkbox
            key={params.id}
            size="small"
            color="primary"
            checked={isChecked}
            disabled={params.row.userId === loggedInUser}
            onChange={(e) =>
              handleCheckboxChange(
                params.row.userId,
                UserRoles.Admin,
                e.target.checked
              )
            }
          />
        );
      },
    },
    {
      field: "isCounter",
      headerName: "Counter",
      width: 80,
      minWidth: 80,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const isChecked = params.row.isCounter;
        return (
          <Checkbox
            key={params.id}
            size="small"
            color="primary"
            checked={isChecked}
            onChange={(e) =>
              handleCheckboxChange(
                params.row.userId,
                UserRoles.Counter,
                e.target.checked
              )
            }
          />
        );
      },
    },
    {
      field: "isAuditor",
      headerName: "Auditor",
      width: 80,
      minWidth: 80,
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        const isChecked = params.row.isAuditor;
        return (
          <Checkbox
            key={params.id}
            size="small"
            color="primary"
            checked={isChecked}
            onChange={(e) =>
              handleCheckboxChange(
                params.row.userId,
                UserRoles.Auditor,
                e.target.checked
              )
            }
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Enable/Disable User",
      sortable: false,
      flex: 1,
      width: 150,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          {" "}
          <ActiveSwitchStyled
            checked={params.row.isDisabled}
            onChange={(event) => {
              const newActiveStatus = event.target.checked;
              handleStatusChangeToggle(params.row.id, newActiveStatus);
            }}
          />
        </Box>
      ),
    },
  ];

  const handleStatusChange = async (id: any, newActiveStatus: boolean) => {
    try {
      const payload = {
        userId: id,
        isDisable: newActiveStatus,
        clientId: clientId,
        modifiedBy: loggedInUser,
      };
      const response = await userService.updateUserStatus(payload);
      if (response.isSuccess) {
        setRefreshTrigger(!refreshTrigger);
        const successMessage = !newActiveStatus
          ? "The user is disabled successfully."
          : "The user is enabled successfully.";

        setSnackbarMessage(successMessage);
        setSeverity("success");
        setOpenSnackbar(true);
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
      } else {
        setSnackbarMessage("Failed to update status.");
        setSeverity("error");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Failed to update status:", error);
      setSnackbarMessage("Error updating status.");
      setSeverity("error");
      setOpenSnackbar(true);
    }
  };
  const [dialogTitle, setDialogTitle] = useState("");
  const handleStatusChangeToggle = (id: any, newStatus: any) => {
    if (newStatus === true) {
      setDialogTitle(DialogMessage.Dialog_Title_Enable_User);
      setDialogMessage(DialogMessage.Dialog_Message_Enable_User);
      setConfirmAction(() => () => handleStatusChange(id, newStatus));
      setInactiveDialogOpen(true);
    } else {
      setDialogTitle(DialogMessage.Dialog_Title_Disable_User);
      setDialogMessage(DialogMessage.Dialog_Message_Disable_User);
      setConfirmAction(() => () => handleStatusChange(id, newStatus));
      setInactiveDialogOpen(true);
    }
  };

  const [selectedRoleValue, setSelectedRoleValue] = useState<string>("");
  //Fetch Tags By User
  const fetchUserByClient = async () => {
    setLoading(true);
    try {
      const filterRoleId = selectedRoleValue;
      const payload = {
        clientId: clientId,
        searchParam: searchKey,
        currentPage: page + 1,
        pageSize: pageSize,
        ...(filterRoleId ? { filterRoleId } : {}),
      };
      const response = await userService.getUserList(payload);
      if (response.isSuccess) {
        setEmptyState(
          !searchKey && !filterRoleId && response.users.length === 0
        );
        setUsers(
          response.users.map((user: IUserList) => ({
            ...user,
            id: user.userId,
          }))
        );
        setTotalRecord(response.totalRecords);
        setPageCount(Math.ceil(response.totalRecords / pageSize));
      } else {
        setSnackbarMessage("Failed to fetch users.");
        setSeverity("error");
        setOpenSnackbar(true);
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setSnackbarMessage("Failed to fetch users.");
      setSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Trigger API call on search key change when it has at least 3 characters
  useEffect(() => {
    if (searchKey.length >= 3 || searchKey.length === 0) {
      fetchUserByClient();
    }
  }, [searchKey, selectedRoleValue, clientId, refreshTrigger, page]);

  const handleRefresh = () => {
    handleFilterClear();
    setRefreshTrigger(!refreshTrigger);
  };
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "permissions",
      headerName: "Permissions",
      headerAlign: "center",
      children: [
        { field: "isAdmin" },
        { field: "isCounter" },
        { field: "isAuditor" },
      ],
    },
  ];
  const [inactiveDialogOpen, setInactiveDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [confirmAction, setConfirmAction] = useState<(() => void) | null>(null); // Holds the function to call on confirm
  const handleConfirm = () => {
    if (confirmAction) {
      confirmAction();
      setConfirmAction(null);
    }
    setInactiveDialogOpen(false);
  };

  const onClickInactiveDialogClose = () => {
    setInactiveDialogOpen(false);
    setConfirmAction(null);
  };
  const handleCheckboxChange = (
    userId: number,
    roleId: number,
    isActive: boolean
  ) => {
    const message = isActive
      ? DialogMessage.Dialog_Title_Assign_Role
      : DialogMessage.Dialog_Title_Remove_Role;
    setTimeout(() => {
      setDialogTitle(message);
      setDialogMessage(" ");
      setInactiveDialogOpen(true);
    }, 1000);

    // Define the function to be executed on confirmation
    setConfirmAction(() => async () => {
      const payload = {
        userId,
        clientId,
        roleId,
        isActive,
        modifiedBy: loggedInUser,
      };

      try {
        const response = await userService.updateUserRole(payload);
        if (response.isSuccess) {
          setSnackbarMessage(
            `Role ${isActive ? "assigned" : "removed"} successfully.`
          );
          setSeverity("success");
          handleRefresh();
        } else {
          setSnackbarMessage("Failed to update user role.");
          setSeverity("error");
        }
      } catch (error) {
        setSnackbarMessage("API error: Failed to update user role.");
        setSeverity("error");
      } finally {
        setOpenSnackbar(true);
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
      }
    });
  };

  return (
    <div className="content_container">
      <Grid container>
        <Grid item container xs={12} className={styles.headingContainer}>
          <h3 className={styles.page_title}>Users</h3>
          {!emptyState && (
            <Button variant="contained" onClick={onClickAddUserDialogOpen}>
              Scan to add users
            </Button>
          )}
        </Grid>
      </Grid>
      {emptyState ? (
        <Suspense fallback={<div>Loading...</div>}>
          {emptyState && (
            <EmptyDashboard
              title="No Users Found !"
              subtitle="Start Adding Users by clicking the button below."
              buttonText="Scan to add users"
              buttonFunction={onClickAddUserDialogOpen}
            />
          )}
        </Suspense>
      ) : (
        <Grid container item spacing={2}>
          <Grid item container xs={12} className={styles.searchFilterContainer}>
            <Grid item sm={10}>
              <SearchInput searchKey={searchKey} onChange={setSearchKey} />
            </Grid>
            <Grid item sm={2}>
              <Suspense fallback={<div>Loading...</div>}>
                {handleFilterOpen && (
                  <FilterDropdown
                    selectedValue={
                      roleOptions.find(
                        (option) => option.value === selectedRoleValue
                      )?.label || ""
                    }
                    options={roleOptions.map((option) => ({
                      value: option.value,
                      label: option.label,
                    }))}
                    onChange={handleFilterChange}
                    onClear={handleFilterClear}
                    onOpen={handleFilterOpen}
                    onClose={handleFilterClose}
                    filterOpen={filterOpen}
                  />
                )}
              </Suspense>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={styles.datagrid_container}>
            <Datatable
              rows={users}
              columns={columns}
              page={page}
              rowsPerPage={rowsPerPage}
              pagination
              paginationMode="server"
              onPageChange={handlePageChange}
              rowCount={count}
              loading={loading}
              error={error}
              checkboxSelection={false}
              columnGroupingModel={columnGroupingModel}
            ></Datatable>
          </Grid>
        </Grid>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        {AddUserDialogOpen && (
          <AddUserDialog
            open={AddUserDialogOpen}
            onClose={onClickAddUserDialogClose}
          />
        )}
        <CustomPagination
          page={page}
          pageCount={pageCount || 1}
          handleFirstPage={handleFirstPage}
          handleLastPage={handleLastPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </Suspense>

      <InactiveDialog
        open={inactiveDialogOpen}
        onClose={onClickInactiveDialogClose}
        onConfirm={handleConfirm}
        title={dialogTitle}
        message={dialogMessage}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default Users;
