import React, { Suspense, useCallback, useEffect, useState } from "react";
import styles from "./items.module.scss";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  styled,
} from "@mui/material";
import Icon from "@mdi/react";
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiClose,
  mdiDotsVertical,
  mdiTrayArrowDown,
} from "@mdi/js";
import {
  ActiveSwitch,
  CustomPagination,
  Datatable,
  InactiveDialog,
} from "../../../../components";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { itemService } from "../../../../api/services";
import { IAddItem } from "../../../../interfaces/IAddItem";
import { MasterSection } from "../../../../enums/MasterSection";
import { AddItemDialog, ImportSheetDialog } from "../../../../containers";
import { DialogMessage } from "../../../../utils/Enum/DialogMessage";
import { useClient } from "../../../../utils/ClientContext";
import { MasterExport } from "../../../../utils/Enum/MasterExport";
import { IMasterExportProps } from "../../../../interfaces";

const ActionMenu: React.FC<{
  id: number;
  rowData: any;
  onEdit: (data: any) => void;
}> = ({ id, rowData, onEdit }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit(rowData);
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-controls="action-menu"
        aria-haspopup="true"
        onClick={handleClick}
        aria-label="edit"
      >
        <Icon path={mdiDotsVertical} aria-label="edit" size={1} />
      </IconButton>
      <Menu
        id={`action-menu-${id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={styles.actionMenulist}
      >
        <MenuItem onClick={handleEdit} className={styles.actionMenuitem}>
          Edit
        </MenuItem>
      </Menu>
    </>
  );
};

const Items: React.FC<IMasterExportProps> = ({ onExportMastersData }) => {
  const dialogMessage = DialogMessage.Dialog_Message_Inactive_Item;
  const [formData, setFormData] = useState<IAddItem | null>(null);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [error, setError] = useState<string | null>(null);
  const [emptyState, setEmptyState] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [importSheetDialogOpen, setImportSheetDialogOpen] =
    useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(100);
  const apiRef = useGridApiRef();
  const [severity, setSeverity] = useState<"success" | "error">("success");
// Handles the page change event
const handlePageChange = (newPage: number) => {
  setPage(newPage);
};
const handleFirstPage = () => {
  setPage(0);
};

const handleLastPage = () => {
  setPage(pageCount - 1);
};

const handleNextPage = () => {
  if (page < pageCount - 1) {
    setPage((prev) => prev + 1);
  }
};

const handlePreviousPage = () => {
  if (page > 0) {
    setPage((prev) => prev - 1);
  }
};
  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };
  //Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: "Code",
      width: 150,
      minWidth: 150,
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      minWidth: 150,
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "uomName",
      headerName: "UOM",
      width: 100,
      minWidth: 100,
      flex: 0.8,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "cost",
      headerName: "Cost",
      width: 100,
      minWidth: 100,
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      width: 150,
      minWidth: 120,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" width="100%" height="100%">
          <ActiveSwitch
            isActive={params.row.isActive}
            onConfirmInactive={() => handleMakeInactive(params.row.id)}
            message={dialogMessage}
            onChange={(event) => {
              const newActiveStatus = event.target.checked;
              handleStatusChange(params.row.id, newActiveStatus, false);
            }}
          />
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 120,
      minWidth: 80,
      align: "right",
      renderCell: (params) => (
        <ActionMenu
          id={params.row.id}
          rowData={params.row}
          onEdit={handleEdit}
        />
      ),
    },
  ];
  const handleStatusChange = async (
    id: number,
    isActive: boolean,
    isConfirm: boolean
  ) => {
    if (isActive) {
      try {
        const rowId: string[] = [id.toString()];
        const requestBody = {
          ids: rowId,
          isActive: isActive,
        };
        const response = await itemService.updateItemStatus(requestBody);
        if (response.isSuccess) {
          handleRefresh();
        }
        setSnackbarMessage("Item status updated successfully.");
        setSeverity("success");
        setOpenSnackbar(true);
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
      } catch (error) {
        setSnackbarMessage("Failed to update status.");
        setSeverity("error");
        setOpenSnackbar(true);
      }
    } else {
      if (isConfirm) {
        try {
          const rowId: string[] = [id.toString()];
          const requestBody = {
            ids: rowId,
            isActive: isActive,
          };

          const response = await itemService.updateItemStatus(requestBody);
          if (response.isSuccess) {
            handleRefresh();
            setSnackbarMessage("Item status updated successfully.");
            setSeverity("success");
            setOpenSnackbar(true);
            setTimeout(() => {
              handleCloseSnackbar();
            }, 5000);
          }
        } catch (error) {
          console.error("Failed to update status:", error);
        }
      }
    }
  };

  const handleMakeInactive = (id: number) => {
    handleStatusChange(id, false, true);
  };
  const handleEdit = (data: any) => {
    setFormData(data);
    setAddItemDialogOpen(true);
  };
  const [rows, setRows] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<
    (() => Promise<void>) | undefined
  >(undefined);
  const [inactiveDialogOpen, setInactiveDialogOpen] = useState(false);
  const handleInactiveDialogClose = () => {
    setInactiveDialogOpen(false);
    getItemsList(page);
  };
  const { client } = useClient();
  const clientId = client.id;
  useEffect(() => {
    setRows([]);
    getItemsList(page);
  }, [page,refreshTrigger]);
  
  useEffect(() => {
    setRows([]);
    setPage(0);
    setPageCount(0);
    getItemsList(page);
  }, [clientId]);
 
  const userId = Number(localStorage.getItem("userId"));

  const getItemsList = async (page: number) => {
    setLoading(true);
    try {
      const response = await itemService.getItemsByClient(clientId, page +1);
      if (response.items.length > 0) {
        setEmptyState(false);
        setRows(response.items)
        setPageCount(Math.ceil(response.totalRecords / pageSize));
       
      } else{
        setEmptyState(true);
      }
    } catch (error) {
      setEmptyState(true);
      setSeverity("error");
      setSnackbarMessage("Failed to load items");
      setOpenSnackbar(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const [AddItemDialogOpen, setAddItemDialogOpen] = React.useState(false);
  const onClickAddItemDialogOpen = () => {
    setAddItemDialogOpen(true);
  };
  const handleRefresh = () => {
    getItemsList(page);
    setRefreshTrigger(!refreshTrigger);
  };

  const handleCloseDialog = () => {
    setFormData(null);
    setAddItemDialogOpen(false);
  };

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const handleRowSelectionChange = (newSelection: number[]) => {
    setSelectedRows(newSelection);
  };

  const handleDeselectAll = () => {
    // Deselect all rows
    apiRef.current.setRowSelectionModel([]);
  };

  const makeInactive = async () => {
    if (selectedRows.length > 0) {
      try {
        const requestBody = {
          ids: selectedRows,
          isActive: false,
        };
        const response = await itemService.updateItemStatus(requestBody);
      } catch (error) {
        console.error("API call failed:", error);
      }
      getItemsList(page);
      setSelectedRows([]);
    }
  };
  const handleDialogConfirm = async () => {
    if (pendingAction) {
      await pendingAction();
    }
  };


  const handleMarkInactive = async (selectedRows: number[]) => {
    try {
      const payload = {
        Ids: selectedRows,
        ModifiedBy: userId,
      };
      const response = await itemService.updateItemStatus(payload);
      if (response.isSuccess) {
        handleRefresh();
        handleDeselectAll();
      }
    } catch (err) {
      console.error("Failed to update status", err);
    } finally {
      setInactiveDialogOpen(false);
    }
  };

  return (
    <div className="comman_wrapper">
      {emptyState ? (
        <Grid container item className={styles.dashboard_container_empty}>
          <Grid item xs={12} className={styles.dashboard_empty_content}>
            <img
              src={process.env.PUBLIC_URL + "/assets/blank_screen_img.svg"}
              alt="Blank Screen Img"
            />
            <h3> Your Items are empty at the moment </h3>
            <h5>
              {" "}
              Please import an excel sheet to start adding your inventory.{" "}
            </h5>
            <Grid item className={styles.add_master_btn}>
            <Button
                variant="contained"
                onClick={() => {
                  setImportSheetDialogOpen(true);
                }}
              >
                Import Sheet
              </Button>

              <Button variant="outlined" onClick={onClickAddItemDialogOpen}>
                Add Item
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container item className={styles.datagrid_wrapper}>
          {selectedRows.length > 0 ? (
            <Grid item xs={12} className={styles.datagrid_top_btns}>
              <Button
                variant="outlined"
                onClick={() => setInactiveDialogOpen(true)}
              >
                Make Inactive ({selectedRows.length})
              </Button>
              <Button variant="outlined" onClick={handleDeselectAll}>
                Unselect All ({selectedRows.length})
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className={styles.datagrid_top_btns}>
              <Button
                variant="outlined"
                onClick={() => setImportSheetDialogOpen(true)}
              >
                Import Sheet
              </Button>
              <Button variant="outlined" onClick={onClickAddItemDialogOpen}>
                Add Item
              </Button>
              <Button variant="outlined" onClick={()=>onExportMastersData(MasterExport.Items)}>
                <Icon path={mdiTrayArrowDown} size={1}></Icon>
              </Button>
            </Grid>
          )}
          <Grid item xs={12} className={styles.datagrid_container}>
            <Datatable
              rows={rows}
              columns={columns}
              pagination
              paginationMode="server"
              onPageChange={handlePageChange}
              rowCount={totalCount}
              loading={loading}
              disableSelectionOnClick={false}
              error={error}
              checkboxSelection={true}
              apiRef={apiRef}
              onSelectionModelChange={handleRowSelectionChange}
            ></Datatable>
<CustomPagination
                    page={page}
                    pageCount={pageCount || 1}
                    handleFirstPage={handleFirstPage}
                    handleLastPage={handleLastPage}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                  />
          </Grid>
        </Grid>
      )}
      <Suspense fallback={<div>Loading...</div>}>
              {AddItemDialogOpen && (
                <AddItemDialog
                  open={AddItemDialogOpen}
                  onClose={handleCloseDialog}
                  formData={formData}
                  onSuccess={handleRefresh}
                />
              )}
            {importSheetDialogOpen && (
          <ImportSheetDialog
            open={importSheetDialogOpen}
            onClose={() => {
              setImportSheetDialogOpen(false);
            }}
            section={MasterSection.Items}
            onSuccess={handleRefresh}
          />
        )}
            </Suspense>
            <InactiveDialog
              open={inactiveDialogOpen}
              onClose={() => handleInactiveDialogClose}
              onConfirm={() => handleMarkInactive(selectedRows)}
              message={dialogMessage}
            />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Items;
