import React from "react";
import { createTheme } from "@mui/material/styles";
import { KeyboardArrowDown } from "@mui/icons-material";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 769, // Set 'sm' to 768px
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#115ACB", // Blue
    },
    secondary: {
      main: "#494949", // Dark Grey
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    h2: {
      fontSize: "36px",
      fontWeight: "700",
      color: "#40475B",
    },
    h3: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: " 600",
      color: "#40475B",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontFamily: "Montserrat",
          fontSize: "14px",
          fontWeight: " 600",
          lineHeight: "normal",
          padding: "15px 16px",
          borderRadius: "4px",
          border: " 1px solid #1363DF",
          boxShadow: "none",
          "@media screen and (max-width:768px)": {
            padding: "10px", // Smaller padding for mobile
          },
        },
        contained: {
          background: "#1363DF",
          color: "#FFF",
          "&.Mui-disabled": {
            background: "#1363DF",
            color: "#FFF",
            opacity: "0.5",
            cursor: "not-allowed",
          },
        },
        outlined: {
          borderColor: "#1363DF",
          color: "#1363DF",
        },
      },
    },
    // MuiIconButton: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       // Some CSS
    //       fontFamily: "Montserrat",
    //       fontSize: "14px",
    //       fontWeight: " 600",
    //       lineHeight: "normal",
    //       padding: "12px 10px",
    //       borderRadius: "4px",
    //       border: " 1px solid #1363DF",
    //       boxShadow: "none",
    //       "@media screen and (max-width:768px)": {
    //         padding: "8px 6px", // Smaller padding for mobile
    //       },
    //     },
    //   },
    // },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true, // Always shrink the label
        },
      },
      styleOverrides: {
        root: {
          border: "0",
          "& legend": {
            display: "none",
          },
          "& .MuiInputBase-root": {
            background: "#F0F1F8",
            color: "#000",
            borderRadius: "0px",
            border: "0px",
            //padding: "20px 18px 20px 24px",
          },
          "& .MuiInputBase-input": {
            background: "#F0F1F8",
            color: "#000",
            borderRadius: "0px",
            border: "0px",
            fontSize: "14px",
            padding: "20px 18px 20px 24px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            top: "0px",
            borderColor: "#FFF",
          },
          "&::placeholder": {
            color: "#666", // Placeholder color for standard browsers
          },
          "&::-webkit-input-placeholder": {
            color: "#666", // Placeholder color for WebKit browsers
          },
          "&:-ms-input-placeholder": {
            color: "#666", // Placeholder color for IE
          },
          "&::-ms-input-placeholder": {
            color: "#666", // Placeholder color for Edge
          },
          outlined: {
            "& .MuiOutlinedInput-root": {
              background: "#FFF !important", // Background specific to outlined variant
              borderColor: "#D0D0D0 !important", // Border color for outlined variant
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#D0D0D0", // Custom border color for outlined variant
            },
          },
        },
      },
    },

    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDown,
      },
      styleOverrides: {
        root: {
          border: "0",
          "& legend": {
            display: "none",
          },
          "& .MuiInputBase-root": {
            background: "#F0F1F8",
            color: "#000",
            borderRadius: "0px",
            border: "0px",
            //padding: "20px 18px 20px 24px",
          },
          "&:before": {
            border: "0px !important",
          },

          "& .MuiInputBase-input": {
            background: "#F0F1F8",
            color: "#000",
            borderRadius: "0px",
            border: "0px",
            fontSize: "14px",
            padding: "15px 18px 15px 24px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            top: "0px",
            borderColor: "#FFF",
          },
          "&::placeholder": {
            color: "#666", // Placeholder color for standard browsers
          },
          "&::-webkit-input-placeholder": {
            color: "#666", // Placeholder color for WebKit browsers
          },
          "&:-ms-input-placeholder": {
            color: "#666", // Placeholder color for IE
          },
          "&::-ms-input-placeholder": {
            color: "#666", // Placeholder color for Edge
          },
          "& .MuiFilledInput-input:before": {
            display: "none !important", // Remove underline before focused
          },
          filled: {
            "& .MuiFilledInput-input:before": {
              display: "none !important", // Remove underline before focused
            },
            "& .MuiFilledInput-underline:after": {
              display: "none", // Remove underline after focused
            },
          },
          outlined: {
            "&.Mui-disabled": {
              color: "#545454", // Change text color for disabled state
              WebkitTextFillColor: "#545454", // Ensure text color is applied
            },
          },
        },
        icon: {
          "&.Mui-disabled": {
            color: "#545454", // Change icon color for disabled state
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            padding: "20px 18px 20px 24px",
          },
          "& .MuiAutocomplete-input": {
            padding: "0px !important",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: "relative",
          transform: "none",
          color: "#333333",
          fontSize: "14px",
          marginBottom: "10px",
          fontWeight: "500",
          "& .MuiInputLabel-asterisk": {
            color: "#DB2D3C",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        // Style overrides for the success alert
        standardSuccess: {
          backgroundColor: "#D4F6D4",
          border: "1px solid #1CFF17CC",
          color: "#494949",
          borderRadius: "4px !important",
          fontWeight: "600",
        },
        // Style overrides for the error alert
        standardError: {
          backgroundColor: "#ECD9D8",
          border: "1px solid #FF0000",
          borderRadius: "4px !important",
          color: "#494949",
          fontWeight: "600",
        },
      },
      defaultProps: {
        variant: "standard",
        iconMapping: {},
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          // You can add general custom styles for the Snackbar here if needed
          maxWidth: "600px", // Example: Limit the width
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          background: "#F0F1F8",
          color: "#000",
          padding: "8px",
          borderRadius: "50px",
          border: "1px solid #9EAAC1",
          gap: "8px",
          "& .Mui-selected": {
            background: "#115ACB !important",
            color: "#FFF !important",
            padding: "10px 40px",
            // Media query for small screens
            "@media (max-width: 768px)": {
              padding: "10px 20px",
            },
          },
          "& .Mui-disabled": {
            border: "none !important", // Change icon color for disabled state
          },
        },
        grouped: {
          padding: "10px",
          borderRadius: "50px",
          border: "0px",
          fontSize: "14px",
          fontWeight: "600",
        },
      },
    },
    // MuiGrid: {
    //   styleOverrides: {
    //     item: {
    //       "@media screen and (max-width:768px)": {
    //         width: "100%",
    //       },
    //     },
    //   },
    // },
  },
});

export default theme;
