import * as React from "react";
import "./App.css";
import "./materialGlobal.scss";
import { Routes } from "./routes";
import { DrawerProvider, AuthProvider } from "./utils";
import theme from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <style>
        {`
          :root {
            --primary-color: ${theme.palette.primary.main};
            --primary-color-light:#e7f0ff;
            --secondary-color: ${theme.palette.secondary.main};
            --font-family: ${theme.typography.fontFamily};
            --heading-font-size: ${theme.typography.h1.fontSize};
            --White: #FFF;
            --grey:#40475b;
            --grey-dark:#494949;
            --grey-light:#d9dde8;
          }
        `}
      </style>
      <DrawerProvider>
        <AuthProvider>
          <Routes></Routes>
        </AuthProvider>
      </DrawerProvider>
    </ThemeProvider>
  );
};

export default App;
