import React, { Suspense, useCallback, useEffect, useState } from "react";
import styles from "./uoms.module.scss";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  styled,
} from "@mui/material";
import Icon from "@mdi/react";
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiClose,
  mdiDotsVertical,
  mdiTrayArrowDown,
} from "@mdi/js";
import {
  ActiveSwitch,
  CustomPagination,
  Datatable,
  InactiveDialog,
} from "../../../../components";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { uomService } from "../../../../api/services";
import { IAddUOM, IMasterExportProps } from "../../../../interfaces";
import { MasterSection } from "../../../../enums/MasterSection";
import { AddUomsDialog, ImportSheetDialog } from "../../../../containers";
import { DialogMessage } from "../../../../utils/Enum/DialogMessage";
import { useClient } from "../../../../utils/ClientContext";
import { MasterExport } from "../../../../utils/Enum/MasterExport";

const ActionMenu: React.FC<{
  id: number;
  rowData: any;
  onEdit: (data: any) => void;
}> = ({ id, rowData, onEdit }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit(rowData);
    handleClose();
  };
  return (
    <>
      <IconButton
        aria-controls="action-menu"
        aria-haspopup="true"
        onClick={handleClick}
        aria-label="edit"
      >
        <Icon path={mdiDotsVertical} aria-label="edit" size={1} />
      </IconButton>
      <Menu
        id={`action-menu-${id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={styles.actionMenulist}
      >
        <MenuItem onClick={handleEdit} className={styles.actionMenuitem}>
          Edit
        </MenuItem>
      </Menu>
    </>
  );
};

const Uoms: React.FC<IMasterExportProps> = ({ onExportMastersData }) => {
  const dialogMessage = DialogMessage.Dialog_Message_Inactive_Uom;
  const [formData, setFormData] = useState<IAddUOM | null>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [count, setCount] = React.useState(0);
  const [emptyState, setEmptyState] = React.useState(false);
  const [inactiveDialogOpen, setInactiveDialogOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [pageCount, setPageCount] = React.useState(0);
  const [importSheetDialogOpen, setImportSheetDialogOpen] =
    useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(100);
  const apiRef = useGridApiRef();
  const [severity, setSeverity] = useState<"success" | "error">("success");
  // Handles the page change event
 // Handles the page change event
 const handlePageChange = (newPage: number) => {
  setPage(newPage);
};
const handleFirstPage = () => {
  setPage(0);
};

const handleLastPage = () => {
  setPage(pageCount - 1);
};

const handleNextPage = () => {
  if (page < pageCount - 1) {
    setPage((prev) => prev + 1);
  }
};

const handlePreviousPage = () => {
  if (page > 0) {
    setPage((prev) => prev - 1);
  }
};
  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };
  //Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const { client } = useClient();
  const clientId = client.id;
  const userId = Number(localStorage.getItem("userId"));
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState<any[]>([]);
  const getUOMList = async (page: number) => {
    setLoading(true);
    try {
     
      const response = await uomService.getUomConversionList(clientId, page +1);
      if (response.uomMasterList.length > 0) {
        setEmptyState(false);
        setRows(response.uomMasterList)
        setPageCount(Math.ceil(response.totalRecords / pageSize));
      
      }else{
        setEmptyState(true);
      }
    } catch (error) {
      setEmptyState(true);
      setSeverity("error");
      setSnackbarMessage("Failed to load UOMs");
      setOpenSnackbar(true);
      console.error(error);
    } finally {
    setLoading(false);
    }
  };
  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: "Code",
      width: 150,
      minWidth: 150,
      flex: 3.5,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "primaryUomName",
      headerName: "Primary UOM",
      width: 150,
      minWidth: 150,
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "conversionRate",
      headerName: "Conversion",
      width: 100,
      minWidth: 100,
      flex: 0.8,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "secondaryUomName",
      headerName: "Secondary UOM",
      width: 150,
      minWidth: 150,
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "isActive",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      width: 120,
      minWidth: 120,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" width="100%" height="100%">
          <ActiveSwitch
            message={dialogMessage}
            isActive={params.row.isActive}
            onConfirmInactive={() => handleMakeInactive(params.row.id)}
            onChange={(event) => {
              const newActiveStatus = event.target.checked;
              handleStatusChange(params.row.id, newActiveStatus, false);
            }}
          />
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 120,
      minWidth: 80,
      align: "right",
      renderCell: (params) => (
        <ActionMenu
          id={params.row.id}
          rowData={params.row}
          onEdit={handleEdit}
        />
      ),
    },
  ];

  const handleEdit = (data: any) => {
    setFormData(data);
    setAddUomDialogOpen(true);
  };

  const handleStatusChange = async (
    id: number,
    isActive: boolean,
    isConfirm: boolean
  ) => {
    if (isActive) {
      try {
        const rowId: string[] = [id.toString()];
        const requestBody = {
          ids: rowId,
          isActive: isActive,
        };
        const response = await uomService.updateUOMStatus(requestBody);
      } catch (error) {
        console.error("Failed to update status:", error);
      }
    } else {
      if (isConfirm) {
        try {
          const rowId: string[] = [id.toString()];
          const requestBody = {
            ids: rowId,
            isActive: isActive,
          };
          const response = await uomService.updateUOMStatus(requestBody);
          setSnackbarMessage("UOM status updated successfully.");
          setSeverity("success");
          setOpenSnackbar(true);
          setTimeout(() => {
            handleCloseSnackbar();
          }, 5000);
        } catch (error) {
          console.error("Failed to update status:", error);
          setSnackbarMessage("Failed to update status.");
          setSeverity("error");
          setOpenSnackbar(true);
        }
      }
    }
    getUOMList(page);
  };
  const handleMakeInactive = (id: number) => {
    handleStatusChange(id, false, true);
  };

  const handleMarkInactive = async (selectedRows: number[]) => {
    try {
      const payload = {
        Ids: selectedRows,
        ModifiedBy: userId,
      };
      const response = await uomService.updateUOMStatus(payload);
      if (response.isSuccess) {
        getUOMList(page);
        handleDeselectAll();
      }
    } catch (err) {
      console.error("Failed to update status", err);
    } finally {
      setInactiveDialogOpen(false);
    }
  };

  const [AddUomDialogOpen, setAddUomDialogOpen] = React.useState(false);
  const onClickAddUOMDialogOpen = () => {
    setAddUomDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setFormData(null);
    setAddUomDialogOpen(false);
  };

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const handleRowSelectionChange = (newSelection: number[]) => {
    setSelectedRows(newSelection);
  };

  const handleDeselectAll = () => {
    // Deselect all rows
    apiRef.current.setRowSelectionModel([]);
  };

  const handleRefresh = () => {
    setRows([]);
    getUOMList(page);
  };

  useEffect(() => {
    setRows([]);
    setPage(0);
    setPageCount(0);
    getUOMList(page);
  }, [clientId]);
  
  return (
    <div className="comman_wrapper">
      {emptyState ? (
        <Grid container item className={styles.dashboard_container_empty}>
          <Grid item xs={12} className={styles.dashboard_empty_content}>
            <img
              src={process.env.PUBLIC_URL + "/assets/blank_screen_img.svg"}
              alt="Blank Screen Img"
            />
            <h3> Your UOMs are empty at the moment </h3>
            <h5>
              {" "}
              Please import an excel sheet to start adding your inventory.{" "}
            </h5>
            <Grid item className={styles.add_master_btn}>
              <Button
                variant="contained"
                onClick={() => setImportSheetDialogOpen(true)}
              >
                Import Sheet
              </Button>
              <Button variant="outlined" onClick={onClickAddUOMDialogOpen}>
                Add UOMs
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container item className={styles.datagrid_wrapper}>
          {selectedRows.length > 0 ? (
            <Grid item xs={12} className={styles.datagrid_top_btns}>
              <Button
                variant="outlined"
                onClick={() => setInactiveDialogOpen(true)}
              >
                Make Inactive ({selectedRows.length})
              </Button>
              <Button variant="outlined" onClick={handleDeselectAll}>
                Unselect All ({selectedRows.length})
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className={styles.datagrid_top_btns}>
              <Button
                variant="outlined"
                onClick={() => setImportSheetDialogOpen(true)}
              >
                Import Sheet
              </Button>
              <Button variant="outlined" onClick={onClickAddUOMDialogOpen}>
                Add UOMs
              </Button>
              <Button variant="outlined" onClick={()=>onExportMastersData(MasterExport.UOM)}>
                <Icon path={mdiTrayArrowDown} size={1}></Icon>
              </Button>
            </Grid>
          )}
          <Grid item xs={12} className={styles.datagrid_container}>
            <Datatable
              rows={rows}
              columns={columns}
              page={page}
              rowsPerPage={rowsPerPage}
              rowCount={totalCount}
              onPageChange={handlePageChange}
              loading={loading}
              error={error}
              checkboxSelection={true}
              apiRef={apiRef}
              onSelectionModelChange={handleRowSelectionChange} // Handle row selection changes
            ></Datatable>
             <CustomPagination
                    page={page}
                    pageCount={pageCount || 1}
                    handleFirstPage={handleFirstPage}
                    handleLastPage={handleLastPage}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                  />
          </Grid>
        </Grid>
      )}
     
      <Suspense fallback={<div>Loading...</div>}>
        {AddUomDialogOpen && (
          <AddUomsDialog
            open={AddUomDialogOpen}
            onClose={handleCloseDialog}
            uomData={formData}
            onSuccess={handleRefresh}
          />
        )}
        {importSheetDialogOpen && (
          <ImportSheetDialog
            open={importSheetDialogOpen}
            onClose={() => {
              setImportSheetDialogOpen(false);
            }}
            section={MasterSection.UOMs}
            onSuccess={handleRefresh}
          />
        )}
      </Suspense>
      <InactiveDialog
        open={inactiveDialogOpen}
        onClose={() => setInactiveDialogOpen(false)}
        onConfirm={() => handleMarkInactive(selectedRows)}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Uoms;
