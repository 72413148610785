import * as React from "react";
import styles from "./datatable.module.scss";
import Box from "@mui/material/Box";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useDrawer } from "../../utils/DrawerContext";

const Datatable = (props: any) => {
  // Custom loading overlay
  const CustomLoadingOverlay: React.FC<any> = ({ pageSize, columns }) => {
    return (
      <GridOverlay className={styles.skeletonLoader}>
        {/* Skeleton for DataGrid Rows */}
        {[...Array(pageSize)].map((_, rowIndex) => (
          <div
            key={rowIndex}
            style={{
              display: "flex",
              padding: "10px 0px",
              borderBottom: "1px solid #e0e0e0", // Bottom border
              width: "100%",
            }}
          >
            {props.columns.map((column: any) => (
              <div
                key={column.field}
                style={{
                  flex: column.flex || 1, // Use flex if defined, else default to 1
                  // marginRight: "30px",
                  padding: "0px 10px",
                }}
              >
                <Skeleton
                  key={column.field}
                  variant="rectangular"
                  height={15}
                  style={{ borderRadius: "4px", width: "100%" }}
                />
              </div>
            ))}
          </div>
        ))}
      </GridOverlay>
    );
  };
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100); // 100 default page size
  useEffect(() => {
    if (props.rowCount !== 0) {
      setPageSize(props.rowCount);
    }
  }, [props.rowCount]);
  const totalPages = Math.ceil(props.rowCount / pageSize);

  const { drawerOpen } = useDrawer();

  useEffect(() => {
    // Force DataGrid to resize when drawer state changes
    window.dispatchEvent(new Event("resize"));
  }, [drawerOpen]);
  const handleSelectionModelChange = (newSelection: any) => {
    // Pass the selected IDs to the parent component
    if (props.onSelectionChange) {
      props.onSelectionChange(newSelection);
    }
  };
  return (
    <Box
      className={
        `${styles.datatableContainer}` +
        " ContainerwithTopBanner " +
        `${drawerOpen ? styles.drawerOpenClass : styles.drawerClosedClass}` +
        ` ${props.className}`
      }
      ref={props.gridRef}
      //className= // Toggle the key based on drawer state
    >
      {pageSize > 0 && (
        <DataGrid
          className={styles.datatableContainer_table}
          rows={props.rows}
          //rows={props.loading ? [] : props.rows} // Show empty rows when loading
          columns={props.columns}
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(paginationModel) => {
            const newPage = paginationModel.page ?? 0;
            props.onPageChange(newPage);
          }}
          onRowClick={props.onRowClick}
          slots={{
            // noRowsOverlay: () => (
            //   <CustomNoRowsOverlay overlayButtonLink={props.overlayButtonLink} />
            // ),
            noRowsOverlay: props.loading ? CustomLoadingOverlay : undefined,
          }}
          pageSizeOptions={[10]}
          sx={{
            borderRadius: "0px",
            border: "none",
            "& .MuiDataGrid-columnHeaderRow": {
              background: "#D9DDE8 !important", // Header background color
              color: "#494949 !important", // Header text color
            },
            "& .MuiDataGrid-columnHeaders": {
              position: "sticky",
              top: 0,
              zIndex: 1, // Ensure the header stays on top
              backgroundColor: "#D9DDE8 !important", // Header background color
              color: "#494949 !important", // Header text color
            },
            "& .MuiDataGrid-row--borderBottom": {
              backgroundColor: "#D9DDE8 !important", // Header background color
              color: "#494949 !important", // Header text color
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none", // Remove column separators
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              color: "#494949 !important", // Header text color
            },
            "@media (max-width: 600px)": {
              "& .MuiDataGrid-row": {
                fontSize: "12px", // Smaller font size for rows on mobile
                padding: "4px", // Adjust padding for mobile
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: "14px", // Smaller header font size
              },
            },
          }}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnMenu
          disableColumnSelector
          checkboxSelection={props.checkboxSelection}
          onCellClick={props.onCellClick}
          autoHeight={props.autoHeight}
          onRowSelectionModelChange={props.onSelectionModelChange}
          getRowHeight={() => props.getRowHeight}
          hideFooterPagination={true}
          hideFooter
          columnGroupingModel={props.columnGroupingModel}
          apiRef={props.apiRef}
        />
      )}
    </Box>
  );
};

export default Datatable;
