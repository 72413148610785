import React, { startTransition } from "react";
import { Box, Grid } from "@mui/material";
import styles from "./settings.module.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  mdiAccountCog,
  mdiAccountSettings,
  mdiChevronRight,
  mdiDatabaseCogOutline,
} from "@mdi/js";
import Icon from "@mdi/react";

const Settings: React.FC = () => {
  const navigate = useNavigate();

  const handleAdminNavigation = () => {
    navigate("/settings/inventory-settings", { replace: true });
  };

  const handleMasterNavigation = () => {
    startTransition(() => {
      navigate("/settings/masters", { replace: true });
    });
  };

  const location = useLocation(); // Get the current location

  // Check if the current route is exactly "/settings"
  const isParentRoute = location.pathname === "/settings";

  return (
    <div className="content_container">
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <h3 className={styles.page_title}>Settings</h3>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Box className={styles.outerBox} onClick={handleAdminNavigation}>
            <Icon
              path={mdiAccountCog}
              size={1}
              color="#40475B"
              className={styles.settingIcon}
            />
            <div className={styles.boxContent}>
              <h3> Inventory Setting </h3>
              <p>
                Manage all your inventory level settings.
              </p>
            </div>
            <Icon
              path={mdiChevronRight}
              size={1}
              color="#40475B"
              className={styles.arrowIcon}
            />
          </Box>
          <Box className={styles.outerBox} onClick={handleMasterNavigation}>
            <Icon
              path={mdiDatabaseCogOutline}
              size={1}
              className={styles.settingIcon}
            />
            <div className={styles.boxContent}>
              <h3> Masters ( Database ) </h3>
              <p>
                Manage and access a comprehensive list of all the locations,
                areas, items, unit of measurements.
              </p>
            </div>
            <Icon
              path={mdiChevronRight}
              size={1}
              className={styles.arrowIcon}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
export default Settings;
