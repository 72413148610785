import { Dialog, styled } from "@mui/material";

export const ConfirmationDialogStyle = styled(Dialog)({
  "& .MuiPaper-root": {
    maxWidth: "600px",
    width: "600px",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    alignContent: "center",
    alignItem: "center",
  },
  "& .MuiDialogTitle-root": {
    padding: "40px",
    background: "#082A5E",
    color: "#FFF",
    textAlign: "left",
  },
  "& .MuiDialogContent-root": {
    padding: "40px",
    paddingBottom: "20px",
    textAlign: "left",
    // maxWidth: "80%",
    // margin: "auto",
    "& h2": {
      margin: "0px",
      fontSize: "36px",
      color: "#000",
      background: "#F0F1F8",
      borderRadius: "4px",
      padding: "10px",
      display: "inline-block",
    },
    "& h5": {
      margin: "0px",
    },
  },
  "& .MuiDialogActions-root": {
    gap: "15px",
    padding: "24px",
    paddingTop: "0px",
    justifyContent: "end",
    "& button": {
      width: "auto",
    },
  },
});
export default ConfirmationDialogStyle;
