import UserAxios from "../ICMAxios";


const getUomByClient = async (clientId : Number)=>{
    const response = await UserAxios.get(`UOM/${clientId}`);
    return response.data;
}

const getUomConversionList = async(ClientId : Number , CurrentPage: number )=>{
    const response = await UserAxios.get(`UOM/UOMList/${ClientId}?CurrentPage=${CurrentPage}`);
    return response.data;}

    const addUOM =async(formData: any)=>{
        const response = await UserAxios.post(`UOM/Add`, formData);
        return response.data;
    }
    const updateUOMStatus = async(formData: any)=>{
        const response = await UserAxios.put(`UOM/uom-conversions/status`, formData);
        return response.data;
    }
    const updateUom = async(formData: any)=>{
        const response = await UserAxios.put(`UOM/uom-conversions/update`, formData);
        return response.data;
    }
const uomService ={getUomByClient , getUomConversionList, addUOM, updateUOMStatus,updateUom}

export default uomService;