import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import styles from "./adminSetting.module.scss";
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiClose,
  mdiPercentOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import adminSettingService from "../../../../api/services/AdminSettingService";
import { useClient } from "../../../../utils/ClientContext";

const AdminSetting: React.FC = () => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const userId = Number(localStorage.getItem("userId"));
  const { client } = useClient();
  const clientId = client.id;
  const [isInventoryIdAvailable, setIsInventoryIdAvailable] = useState(false);

  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };

  //Handle close snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Create a state variable for the input value
  const [formValues, setFormValues] = useState({
    inventoryId: 0,
    inventorySettingId: 0,
    auditThreshold: "",
    erroredTags: "",
    errorPercentage: "",
    defaultMisfireThreshold: "",
    defaultMisfirePercentage: "",
  });

  // Create a state variable for the error flags
  const [errors, setErrors] = useState<{
    auditThreshold: boolean | string;
    erroredTags: boolean;
    errorPercentage: boolean;
    defaultMisfireThreshold: boolean;
    defaultMisfirePercentage: boolean;
  }>({
    auditThreshold: false,
    erroredTags: false,
    errorPercentage: false,
    defaultMisfireThreshold: false,
    defaultMisfirePercentage: false,
  });

  // Validate Form
  const validateForm = () => {
    const newErrors: any = {};
    const auditThresholdValue = Number(formValues.auditThreshold);
    if (formValues.auditThreshold === "") {
      newErrors.auditThreshold = "Audit threshold is required.";
    } else if (isNaN(auditThresholdValue)) {
      newErrors.auditThreshold = "Audit threshold must be a number.";
    } else if (auditThresholdValue < 1 || auditThresholdValue > 100) {
      newErrors.auditThreshold = "Audit threshold must be between 1 and 100.";
    } else {
      newErrors.auditThreshold = false;
    }
    setErrors(newErrors);
    return !newErrors.auditThreshold;
  };

  // Fetch existing settings
  useEffect(() => {
    const fetchInventorySettings = async () => {
      try {
        const response = await adminSettingService.getInventorySetting(
          clientId
        );
        if (response.isSuccess && response.inventorySettings) {
          setFormValues((prevState) => ({
            ...prevState,
            inventoryId: response.inventorySettings.inventoryId ?? 0,
            inventorySettingId:
              response.inventorySettings.inventorySettingId ?? 0,
            auditThreshold: (
              response.inventorySettings.auditThreshold ?? 0
            ).toString(),
          }));
          if (Boolean(response.inventorySettings.inventoryId ?? 0)) {
            setIsInventoryIdAvailable(true);
          } else {
            setIsInventoryIdAvailable(false);
          }
        } else {
          setOpenSnackbar(true);
          setSeverity("error");
          setSnackbarMessage(response.error.join(""));
          setTimeout(() => {
            handleCloseSnackbar();
          }, 10000);
        }
      } catch (error: any) {
        const errorMessages = error.response?.data?.errors || [
          "Error occurred while getting settings.",
        ];
        setOpenSnackbar(true);
        setSeverity("error");
        setSnackbarMessage(errorMessages.join(", "));
        setTimeout(() => {
          handleCloseSnackbar();
        }, 10000);
      }
    };

    fetchInventorySettings();
  }, [clientId]);

  //Handle input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "auditThreshold") {
      const numberValue = parseFloat(value);

      if (value === "") {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: "",
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Audit threshold is required.",
        }));
      } else if (isNaN(numberValue) || !Number.isInteger(numberValue)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Audit threshold must be a whole number.",
        }));
      } else if (numberValue < 1 || numberValue > 100) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Audit threshold must be between 1 and 100.",
        }));
      } else {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false,
        }));
      }
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  //Handle save button
  const handleEditButtonClick = async () => {
    if (validateForm()) {
      if (formValues.auditThreshold === "") {
        setErrors((prevErrors) => ({ ...prevErrors, auditThreshold: true }));
        return;
      }
      try {
        let response: any;
        if (formValues.inventorySettingId !== 0) {
          const settingUpdateData = {
            inventorySettingId: formValues.inventorySettingId,
            threshold: Number(formValues.auditThreshold),
            clientId: clientId,
            userId: userId,
          };
          response = await adminSettingService.updateInventorySetting(
            settingUpdateData
          );
        } else {
          const settingData = {
            inventoryId: formValues.inventoryId,
            threshold: formValues.auditThreshold,
            clientId: clientId,
            userId: userId,
          };
          response = await adminSettingService.addInventorySetting(settingData);
          setFormValues((prevState) => ({
            ...prevState,
            inventorySettingId: response.inventorySettingId,
          }));
        }
        if (response.isSuccess) {
          setOpenSnackbar(true);
          setSeverity("success");
          setSnackbarMessage("Settings saved successfully.");
          setTimeout(() => {
            handleCloseSnackbar();
          }, 1000);
        } else {
          setOpenSnackbar(true);
          setSeverity("error");
          setSnackbarMessage(response.error.join(""));
          setTimeout(() => {
            handleCloseSnackbar();
          }, 10000);
        }
      } catch (error: any) {
        const errorMessages = error.response?.data?.errors || [
          "Error occurred while saving settings.",
        ];
        setOpenSnackbar(true);
        setSeverity("error");
        setSnackbarMessage(errorMessages.join(", "));
        setTimeout(() => {
          handleCloseSnackbar();
        }, 10000);
      } finally {
        setOpenSnackbar(true);
      }
    }
  };

  return (
    <div className="content_container">
      <Grid container item spacing={2}>
        <Grid
          item
          container
          sm={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <h3 className={styles.page_title}>Inventory Setting</h3>
          <Button
            variant="contained"
            onClick={handleEditButtonClick}
            disabled={!isInventoryIdAvailable}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid container item sm={10} xs={12}>
          <Grid container item spacing={2}>
            <Grid item sm={6} xs={12}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="auditThreshold"
                  name="auditThreshold"
                  value={formValues.auditThreshold}
                  label="Audit Threshold"
                  placeholder="Audit Threshold"
                  onChange={handleChange}
                  error={Boolean(errors.auditThreshold)}
                  helperText={errors.auditThreshold || ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon path={mdiPercentOutline} size={0.8} />
                      </InputAdornment>
                    ),
                    inputProps: { min: 1, max: 100, step: 1, type: "number" },
                  }}
                  disabled={!isInventoryIdAvailable}
                />
              </FormControl>
            </Grid>
            {/* <Grid item sm={6} xs={12}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="erroredTags"
                  name="erroredTags"
                  value={formValues.erroredTags}
                  label="Errored Tags"
                  placeholder="Errored Tags"
                  onChange={handleChange}
                  error={errors.erroredTags}
                  helperText={
                    errors.erroredTags ? "Errored Tags is required" : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="errorPercentage"
                  name="errorPercentage"
                  value={formValues.errorPercentage}
                  label="Error Percentage"
                  placeholder="Error Percentage"
                  onChange={handleChange}
                  error={errors.errorPercentage}
                  helperText={
                    errors.errorPercentage ? "Error Percentage is required" : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon path={mdiPercentOutline} size={0.8} />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="defaultMisfireThreshold"
                  name="defaultMisfireThreshold"
                  value={formValues.defaultMisfireThreshold}
                  label="Default Misfire Threshold"
                  placeholder="Default Misfire Threshold"
                  onChange={handleChange}
                  error={errors.defaultMisfireThreshold}
                  helperText={
                    errors.defaultMisfireThreshold
                      ? "Default Misfire Threshold is required"
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon path={mdiPercentOutline} size={0.8} />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="defaultMisfirePercentage"
                  name="defaultMisfirePercentage"
                  value={formValues.defaultMisfirePercentage}
                  label="Default Misfire Percentage"
                  placeholder="Default Misfire Percentage"
                  onChange={handleChange}
                  error={errors.defaultMisfirePercentage}
                  helperText={
                    errors.defaultMisfirePercentage
                      ? "Default Misfire Percentage is required"
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon path={mdiPercentOutline} size={0.8} />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          icon={getIcon()}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default AdminSetting;
