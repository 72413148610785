export enum DialogMessage {
    Dialog_Title_Disable_User = "Are you sure you want to disable this user?",
    Dialog_Message_Disable_User = "They will not be able to access the application.",
    Dialog_Title_Enable_User = "Are you sure you want to enable this user?",
    Dialog_Message_Enable_User = "They will regain access.",
    Dialog_Title_Assign_Role = "Are you sure you want to assign this role?",
    Dialog_Title_Remove_Role = "Are you sure you want to remove this role?",
    Dialog_Message_Inactive_Item = "Please note that after taking this action, you will have to mark the status of the Item as Active again. Do you wish to continue with your selection? ",
    Dialog_Message_Inactive_Location = "Please note that after taking this action, you will have to mark the status of the Location as Active again. Do you wish to continue with your selection?",
    Dialog_Message_Inactive_Uom = "Please note that after taking this action, you will have to mark the status of the UOM as Active again. Do you wish to continue with your selection?",
    Dialog_Title_Force_Audit = "Are you sure you want to perform an audit for this tag?",
    Dialog_Message_Force_Audit = "This will generate an audit for this tag, and the auditor will be auto-assigned.",
    Dialog_Title_Exclude_Tag = "Are you sure you want to exclude this tag from being copied to the next inventory session?",
    Dialog_Message_Exclude_Tag = "By doing this, you will not see this tag when you copy this inventory to the new session.",
}