import React, { useEffect, useState } from "react";
import styles from "./signup.module.scss";
import {
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiCheck,
  mdiClose,
  mdiAlertCircleOutline,
} from "@mdi/js";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  TextField,
  Alert,
} from "@mui/material";
import Icon from "@mdi/react";
import { ILogin, ISignUp } from "../../interfaces";
import { loginService, userService } from "../../api/services";
import { useLocation, useNavigate } from "react-router";
import { Password } from "@mui/icons-material";
import { decryptData } from "../../utils/crypto-js";

const Signup: React.FC = () => {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [clientName, setClientName] = useState<string | null>();
  const [clientId, setClientId] = useState<string | null>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [formValues, setFormValues] = useState<ISignUp>({
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    emailAddress: false,
    password: false,
    confirmPassword: false,
  });

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const trimmedEmail = email.trim();
    if (!emailRegex.test(trimmedEmail)) {
      return false;
    }
    if (!trimmedEmail.includes("@")) {
      return false;
    }
    const domain = trimmedEmail.split("@")[1];
    if (!domain || !domain.includes(".")) {
      return false;
    }
    const domainParts = domain.split(".");
    return domainParts.length === 2;
  };
  
  const isPasswordValid = (password: string): boolean => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    return passwordRegex.test(password);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Blur event for input fields (for validation on blur)
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      firstName:
        name === "firstName"
          ? value.length < 2 || value.length > 20
          : prevErrors.firstName,
      lastName:
        name === "lastName"
          ? value.length < 2 || value.length > 20
          : prevErrors.lastName,
      emailAddress:
        name === "emailAddress"
          ? !validateEmail(value)
          : prevErrors.emailAddress,
      password:
        name === "password" ? !isPasswordValid(value) : prevErrors.password,
      confirmPassword:
        name === "confirmPassword"
          ? value !== formValues.password
          : prevErrors.confirmPassword,
    }));
  };

  // Validate Form
  const validateForm = () => {
    const newErrors = {
      firstName:
        formValues.firstName.length < 2 || formValues.firstName.length > 20,
      lastName:
        formValues.lastName.length < 2 || formValues.lastName.length > 20,
      emailAddress: !validateEmail(formValues.emailAddress),
      password: !isPasswordValid(formValues.password),
      confirmPassword: formValues.confirmPassword !== formValues.password,
    };
    setErrors(newErrors);
    return !Object.values(newErrors).includes(true);
  };

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Dynamically assign custom icons based on severity
  const getIcon = () => {
    if (severity === "success") {
      return <Icon path={mdiCheck} size={1} />; // Custom success icon
    }
    return <Icon path={mdiAlertCircleOutline} size={1} />; // Custom error icon
  };
  useEffect(() => {
    const isValid =
      formValues.firstName !== "" &&
      formValues.lastName !== "" &&
      formValues.emailAddress !== "" &&
      formValues.password !== "" &&
      formValues.confirmPassword !== "" &&
      !errors.firstName &&
      !errors.lastName &&
      !errors.emailAddress &&
      !errors.password &&
      !errors.confirmPassword;
    setIsFormValid(isValid);
  }, [formValues, errors]);

  const location = useLocation();
  useEffect(() => {
    const encryptedData = location.search.slice(1);
    if (encryptedData) {
      try {
        // Decrypt the data
        const decryptedData = decryptData(encryptedData);
        const params = new URLSearchParams(decryptedData);
        setClientId(params.get("clientId"));
        setClientName(params.get("clientName"));
      } catch (error) {
        console.error("Decryption failed:", error);
      }
    }
  }, [location]);
  
  const onRegister = async () => {
    if (validateForm() && isFormValid) {
      setIsSubmitting(true);
      try {
        const payload = {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          email: formValues.emailAddress,
          password: formValues.password,
          clientId: clientId,
        };
        const response = await userService.signUp(payload);
        if (response.isSuccess) {
          setSnackbarMessage("Signed Up successfully.");
          setSeverity("success");
          setOpenSnackbar(true);
          navigate("/login");
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            emailAddress: response.errors.includes(
              "This email address is already registered."
            ),
          }));
        }
      } catch (error: any) {
        const errorMessages = error.response?.data?.errors || [
          "Error occurred while creating your account. Please try again later.",
        ];
        setSeverity("error");
        setSnackbarMessage(errorMessages.join(", "));
        setOpenSnackbar(true);
        console.error(error);
      }finally {
        setIsSubmitting(false); 
      }
    }
  };

  return (
    <Grid container className={styles.full_height_section}>
      <Grid item sm={6} className={styles.login_left}>
        <div className={styles.login_left_content}>
          <h3> ICM </h3>
          <p>
            ICM is designed to bring your inventory count process into the 21st
            Century, empowering businesses to achieve greater accuracy and
            efficiency in managing physical inventory.
          </p>
        </div>
      </Grid>
      <Grid item sm={6} className={styles.login_right}>
        <div className={styles.login_right_content}>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <h3> Create your acccount </h3>
            </Grid>
            <Grid item xs={6}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="firstName"
                  name="firstName"
                  required
                  value={formValues.firstName}
                  label="First Name"
                  placeholder="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{ maxLength: 20 }}
                  error={errors.firstName}
                  helperText={
                    errors.firstName
                      ? "First name is required and must be at least 2 characters long."
                      : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="lastName"
                  name="lastName"
                  required
                  value={formValues.lastName}
                  label="Last Name"
                  placeholder="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{ maxLength: 20 }}
                  error={errors.lastName}
                  helperText={
                    errors.lastName
                      ? "Last name is required and must be at least 2 characters long."
                      : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="emailAddress"
                  name="emailAddress"
                  required
                  type="email"
                  value={formValues.emailAddress}
                  label="Email Address"
                  placeholder="Email Address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.emailAddress}
                  helperText={
                    errors.emailAddress
                      ? formValues.emailAddress
                        ? "Please enter a valid email address."
                        : "Email address is required."
                      : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="password"
                  name="password"
                  required
                  type={showPassword ? "text" : "password"}
                  value={formValues.password}
                  label="Password"
                  placeholder="Enter Your Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{ maxLength: 16 }}
                  error={errors.password}
                  helperText={
                    errors.password
                      ? "Password must include at least one uppercase letter, one lowercase letter, one number, one special character, no blank space allowed, and must be 8 characters long."
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <Icon path={mdiEyeOffOutline} size={0.7} />
                          ) : (
                            <Icon path={mdiEyeOutline} size={0.7} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  required
                  type={showConfirmPassword ? "text" : "password"}
                  value={formValues.confirmPassword}
                  label="Confirm Password"
                  placeholder="Enter Your Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.confirmPassword}
                  helperText={
                    errors.confirmPassword
                      ? formValues.confirmPassword === ""
                        ? "Confirm password is required."
                        : "Passwords do not match."
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Icon path={mdiEyeOffOutline} size={0.7} />
                          ) : (
                            <Icon path={mdiEyeOutline} size={0.7} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className="fieldWrapper" fullWidth>
                <TextField
                  id="companyName"
                  name="companyName"
                  value={clientName}
                  label="Company Name"
                  placeholder="Company Name"
                  onChange={handleChange}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                id="register"
                fullWidth
                variant="contained"
                disabled={!isFormValid || isSubmitting}
                onClick={onRegister}
              >
                Register
              </Button>
            </Grid>
          </Grid>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar}
          autoHideDuration={10000}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={severity}
            icon={getIcon()}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default Signup;
