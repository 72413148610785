import ICMAxios from '../ICMAxios';



const exportItemDataToExcel = async (cleintId: number) => {
    const response = await ICMAxios.get(`/Item/ExportMasterDataToExcel/${cleintId}`);
    return response.data;
};

const exportLocationDataToExcel = async (cleintId: number) => {
    const response = await ICMAxios.get(`/Location/ExportMasterDataToExcel/${cleintId}`);
    return response.data;
};

const exportUOMDataToExcel = async (cleintId: number) => {
    const response = await ICMAxios.get(`/UOM/ExportMasterDataToExcel/${cleintId}`);
    return response.data;
};

const MasterExportService = { exportItemDataToExcel, exportLocationDataToExcel, exportUOMDataToExcel }
export default MasterExportService;