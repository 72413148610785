import ICMAxios from "../ICMAxios";

const getInventoryCompletionDataAsync = async (clientId: any , inventoryId: any) => {
    const response = await ICMAxios.get(`/Inventory/GetInventoryCompletionDataAsync/${clientId}/${inventoryId}`);
    return response.data;
}

const getUserStatusAsync = async (formData: any) => {
    const response = await ICMAxios.post(`/Inventory/GetUserStatusAsync`, formData);
    return response.data;
}

const getInventoryMasterStatusAsync = async (clientId: any) => {
    const response = await ICMAxios.get(`/Inventory/GetInventoryMasterStatusAsync/${clientId}`);
    return response.data;
}

const dashboardService = { getInventoryCompletionDataAsync, getUserStatusAsync, getInventoryMasterStatusAsync }
export default dashboardService;