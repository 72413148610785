import React, { useCallback, useEffect, useState } from "react";
import { styled, Switch, Typography, Box } from "@mui/material";
import { InactiveDialog } from "..";

interface ActiveSwitchProps {
  isActive: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onConfirmInactive?: () => void;
  message?:string;
  title?:string
}

export const ActiveSwitchStyled = styled(Switch)({
  width: 38, // Updated width
  height: 24, // Updated height
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 19, // Adjusted width for active state
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(10px)", // Adjusted for new width
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(14px)", // Adjusted for new width
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#414ED5",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 19, // Adjusted thumb width
    height: 19, // Adjusted thumb height
    borderRadius: 10, // Updated for a circular thumb
    transition: "all 200ms",
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2, // Updated for new height
    opacity: 1,
    backgroundColor: "#d8d8d8",
    boxSizing: "border-box",
  },
});

const ActiveSwitch: React.FC<ActiveSwitchProps> = ({
  isActive,
  onChange,
  onConfirmInactive,
  message,
  title
}) => {
  const [InactiveDialogOpen, setInactiveDialogOpen] = useState(false);
  const [checked, setChecked] = useState(isActive);
  const [pendingChecked, setPendingChecked] = useState<boolean | null>(null);

  useEffect(() => {
    setChecked(isActive);
  }, [isActive]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;

    if (!newChecked) {
      setPendingChecked(newChecked);
      setInactiveDialogOpen(true);
    } else {
      setChecked(newChecked);
      if (onChange) {
        onChange(event);
      }
    }
  };

  const onClickInactiveDialogClose = useCallback(() => {
    setInactiveDialogOpen(false);
    setPendingChecked(null); // Reset pending change
  }, []);

  const handleConfirm = useCallback(() => {
    if (pendingChecked !== null) {
      setChecked(pendingChecked);
      if (onChange) {
        onChange({
          target: { checked: pendingChecked },
        } as React.ChangeEvent<HTMLInputElement>);
      }
    }
    if (onConfirmInactive) {
      onConfirmInactive();
    }
    setInactiveDialogOpen(false);
    setPendingChecked(null); // Reset pending change after confirmation
  }, [onChange, onConfirmInactive, pendingChecked]);

  return (
    <Box display="flex" alignSelf="center" alignItems="center">
      <ActiveSwitchStyled checked={checked} onChange={handleChange} />
      <Typography variant="body2" sx={{ ml: 1 }}>
        {checked ? "Active" : "Inactive"}
      </Typography>
      <InactiveDialog
        open={InactiveDialogOpen}
        onClose={onClickInactiveDialogClose}
        onConfirm={handleConfirm}
        message={message} 
        title={title}
      />
    </Box>
  );
};

export default ActiveSwitch;
