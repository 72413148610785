import { IAddClient } from "../../interfaces";
import ICMAxios from "../ICMAxios";

const fetchClients = async (userId: any) => {
    const response = await ICMAxios.get(`Client/GetAllClients/${userId}`);
    return response.data;
}
const addClient =async(formData: IAddClient)=>{
    const response = await ICMAxios.post(`Client/AddClient`, formData);
    return response.data;
}

const getCountryAndStateData = async ()=>{
    const response = await ICMAxios.get('Client/GetStateAndCountry');
    return response.data;
}
const clientService = {  fetchClients, addClient, getCountryAndStateData}
export default clientService;